<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Layout from '@/views/layouts/main3'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'
import i18n from '@/i18n'
import DatePicker from 'vue2-datepicker'
import global_func from '@/global_function'
import moment from 'moment'
import Swal from 'sweetalert2'
import PlanDetail from '@/components/modal/plans_detail'
import ModalMap from '@/components/modal/map'
import DisplayLocation from '@/components/location/display_start_end_location'
import ModalCompany from '@/components/modal/company'
import topbar from '@/components/horizontal-topbar'
import ModalProof from '@/components/modal/proof_of_carrier'

export default {
  page: {
    title: 'แผนงานรถว่าง',
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar, DatePicker, PlanDetail, ModalMap, DisplayLocation, ModalCompany, topbar, ModalProof },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('plans_carrier_display')
    return {
      // bank -dev
      star_flag:false,
      fv_flag:false,
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      check_lang: true,
      lang: lang_obj,
      url_params_search_flag: true,

      //DISPLAY VARIABLE
      plans_display_status: false,
      display_as_accept_estimate: false, //1 = Accept estimate, else = offer

      // sort control
      search_plans_text: '', // รหัสแผนงาน สำหรับการค้นหา
      search_date_start: '',
      order_sel: '0',
      sort_by_status: '0',
      // goods data
      //dataGoodsType_master: [],
      dataGoodsType_data: [],
      // truck data
      dataTruckType: [],
      dataTruckTypeGroup: [],
      // plans data
      plans_data: [],
      plans_data_status: true,
      plans_row_data: [], // ข้อมูลที่แสดงในแถวแผนงาน
      plan_data_selcet: [],
      plan_location_display: [
        //{ name: "บริษัท ดี.ที.ซี. เอ็นเตอร์ไพรส์ จำกัด (สำนักงานใหญ่) ทางด่วนขั้นที่ 1 บางนาเหนือ บางนา กรุงเทพมหานคร1", date_appointment: "30/07/2021 00:00"},
        //{ name: "ถนนเรืองสวัสดิ์ ธาตุเชิงชุม เมืองสกลนคร สกลนคร ธาตุเชิงชุม เมืองสกลนคร สกลนคร1", date_appointment: "28/07/2021 00:00"},
      ],
      // plan display
      activeArrow: [],
      active_tab: 0,

      data_service_etc: [
        {
          service_etc_id: 1,
          name_th: 'พนักงานช่วยขนของ',
          name_en: 'พนักงานช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 2,
          name_th: 'พนักงานเพิ่มเติม',
          name_en: 'พนักงานเพิ่มเติม2',
          service_sel_status: '',
        },
        {
          service_etc_id: 3,
          name_th: 'บริการพาเลทรองพื้น',
          name_en: 'บริการพาเลทรองพื้น2',
          service_sel_status: '',
        },
        {
          service_etc_id: 4,
          name_th: 'อุปกรณ์ช่วยขนของ',
          name_en: 'อุปกรณ์ช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 5,
          name_th: 'อื่นๆ',
          name_en: 'อื่นๆ2',
          service_sel_status: '',
        },
      ],

      service_provider_raw_data: [],
      plans_offer_raw_data: [],
      estimate_raw_data: [],

      //OFFER LIST
      service_provider_data: [],
      offer_provider_data: [],
      sort_by_offer_status: '0',
      search_offer_text: '',
      //END OFFER LIST

      //OFFER MODAL
      offer_truck_type: null, //{ id: "3"}, name:"รถบรรทุก 4 ล้อ"
      offer_upload_file: null, //REAL FILE
      offer_upload_display: null, //DISPLAY NAME
      offer_correct_file: true,
      modal_offer: {
        id_order: null,
        good_member_id: null,
        transpot_member_id: null,
        truck_quantity: '',
        cost: '',
        note: '',
      },
      option_truck_type: [],
      //END OFFER MODAL

      //MODAL DETAIL
      offer_detail_data: {},
      //END MODAL DETAIL

      //OFFER HISTORY
      service_offer_history: [],
      offer_history_data: [],
      sort_by_offer_history_status: '0',
      search_offer_history_text: '',
      //END OFFER HISTORY DATA

      //MODAL CANCEL
      option_cancel: [
        { option_id: 1, name: 'ยกเลิก' },
        { option_id: 2, name: 'รถไม่เพียงพอ' },
      ],
      id_order_cancel: null,
      id_cancel_display: null,
      id_cancel_sel: null,
      //MODAL CANCEL

      //VARIABLE ACCEPT LIST
      es_raw_order_data: null,
      es_raw_estimate_data: null,
      es_goodmember_offer_data: [],

      p_estimate_list: null,
      estimate_display_type: 'one',
      estimate_one: [],
      estimate_all: [],
      //END VARIABLE ACCEPT LIST
      activeScroll:false,
      data_truck_bank:[],
      selected: [], // Must be an array reference!
      options: [
        { text: 'Orange', value: 'orange' },
        { text: 'Apple', value: 'apple' },
        { text: 'Pineapple', value: 'pineapple' },
        { text: 'Grape', value: 'grape' },
      ],
      filter:'',
      data_id_carrier:null,
      data_id_order:null,
      type:0,
      subscribe_data : [],
    }
  },
  computed: {
    filters() {
      return this.data_truck_bank.filter(row => {
        const blackbox_id = row.blackbox_id.toString().toLowerCase()
        const name_model = row.name_model.toLowerCase()
        const searchTerm = this.filter.toLowerCase()

        return (
          blackbox_id.includes(searchTerm) ||
          name_model.includes(searchTerm)
        )
      })
    },
  },
  watch: {
    async offer_truck_type(val){
      this.selected = []
      this.data_truck_bank = []
      this.filter = ''
      service
        .postService('getMemberVehicle-bank', {
          member_data: this.account_data,
          account_lang: this.account_lang,
          id_group_vehicle: val.id_group,
          id_type_vehicle: val.id_model,
        })
        .then(rp => {
          this.data_truck_bank = rp.data
          // console.log(this.plan_data_selcet.rawData.truck_data)
          this.selected = this.plan_data_selcet.rawData.truck_data.map(e=>{
            return e.id_member_vehicle
          })
        })
      // let result = await this.axios.post('http://localhost:8080/getMemberVehicle-bank',{
      //   member_data: this.account_data,
      //   account_lang: this.account_lang,
      //   id_group_vehicle: val.id_group,
      //   id_type_vehicle: val.id_model,
      // },{})
      // this.data_truck_bank = result.data.data
      // console.log(this.plan_data_selcet.rawData.truck_data)
      // this.selected = this.plan_data_selcet.rawData.truck_data.map(e=>{
      //   return e.id_member_vehicle
      // })
    },
    search_date_start: {
      handler: function() {
        // console.log(this.search_date_start)
        this.search_plan('search_date')
      },
      deep: true,
    },
  },
  async mounted() {
    window.addEventListener('wheel', this.bank_check_scroll)
    await this.getTruckType()
    await this.getTruckTypeGroup()
    this.makeTruckTypeSelect()
    await this.getDataGoodsType()
    await this.getPlansData()
    await this.getSubscribeData()

  },
  methods: {
    async create_truck(){
      // console.log(this.offer_truck_type.id_group)
      // console.log(this.data_id_carrier)
      // console.log(this.selected)
      // console.log(this.data_id_order)
      // console.log('this.type',this.type)
      // id_type_vehicle
      if(this.type == 0){
        // console.log('เพิ่ม')
        service
          .postService('createMatchCar', {
            id_carrier:this.data_id_carrier,
            arr_id_member_vehicle: this.selected,
            id_order:this.data_id_order,
          })
          .then(rp => {
            let result = {
              data : rp,
            }
            // console.log(result.data)
            if (result.data.code == 200) {
              this.$bvModal.hide('modal-truck')
              this.getPlansData()
              this.backToPlanListDisplay()
            } else {
              alert('error')
            }
          })
        // let result = await this.axios.post('http://localhost:8080/createMatchCar',{
        //   id_carrier:this.data_id_carrier,
        //   arr_id_member_vehicle: this.selected,
        //   id_order:this.data_id_order,
        // },{})
        // console.log(result.data)
        // if(result.data.code == 200){
        //   this.$bvModal.hide('modal-truck')
        //   await this.getPlansData()
        //   this.backToPlanListDisplay()
        // }else{
        //   alert('error')
        // }
      }else if(this.type == 1){
        // console.log('แก้ไข')
        // let result = await this.axios.post('http://localhost:8080/editMatchCar',{
        //   id_carrier:this.data_id_carrier,
        //   arr_id_member_vehicle: this.selected,
        //   id_order:this.data_id_order,
        // },{})
        // console.log(result.data)
        // if(result.data.code == 200){
        //   this.$bvModal.hide('modal-truck')
        //   await this.getPlansData()
        //   this.backToPlanListDisplay()
        // }else{
        //   alert('error')
        // }
        service
          .postService('editMatchCar', {
            id_carrier:this.data_id_carrier,
            arr_id_member_vehicle: this.selected,
            id_order:this.data_id_order,
          })
          .then(rp => {
            let result = {
              data : rp,
            }
            // console.log(result.data)
            if(result.data.code == 200){
              this.$bvModal.hide('modal-truck')
              this.getPlansData()
              this.backToPlanListDisplay()
            }else{
              alert('error')
            }
          })
      }
    
    },
    async delete_truck(val){
      service
        .postService('deleteMatchCar', {
          id_carrier:this.data_id_carrier,
          arr_id_member_vehicle: this.selected,
          id_order:this.data_id_order,
        })
        .then(rp => {
          let result = rp
          // console.log(result.data)
          if(result.data.code == 200){
            this.getPlansData()
            this.backToPlanListDisplay()
          }else{
            alert('error')
          }
        })
    },
    async addTruck(val,type){
      this.type = type
      if(val.length > 0){
        // แก้ไข
        
        this.offer_truck_type = {
          id_group: val[0].id_group_vehicle,
          id_model: val[0].id_model,
          name: val[0].name_model_th,
        }
        
        // blackbox_id
        this.$bvModal.show('modal-truck')
      } else {
         
        // เพิ่ม
        this.selected = []
        // console.log(this.data_id_carrier)
        this.offer_truck_type = null
        this.$bvModal.show('modal-truck')

        service
          .postService('getMemberVehicle-bank', {
            member_data: this.account_data,
            account_lang: this.account_lang,
            id_group_vehicle:'',
            id_type_vehicle:'',
          })
          .then(rp => {
            let result = rp.data
            this.data_truck_bank = result
          })

        // console.log(result.data.data)
      }
    },
    bank_check_scroll(){
      if(this.$refs.bankscrol != undefined) {
        if(this.$refs.bankscrol.scrollElement.scrollTop > 0){
          this.activeScroll = true
        }else{
          this.activeScroll = false
        }
      }
    },
    toTop() {
      if(this.$refs.bankscrol != undefined) {
        this.$refs.bankscrol.scrollElement.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    updateStar(val, index, tab){
      // console.log(val)
      var that = this
      let mode = 'unsub'
      if(val.star_flag == '0') {
        mode = 'sub'
      }

      if(tab == 'provider_data') {
        if(this.offer_provider_data[index] != undefined) {
          if(this.offer_provider_data[index].star_flag == '0') {
            this.offer_provider_data[index].star_flag = '1'
          } else {
            this.offer_provider_data[index].star_flag = '0'
          }
        }
      } else if(tab == 'goodmember_offer_data') {
        if(this.es_goodmember_offer_data[index] != undefined) {
          if(this.es_goodmember_offer_data[index].star_flag == '0') {
            this.es_goodmember_offer_data[index].star_flag = '1'
          } else {
            this.es_goodmember_offer_data[index].star_flag = '0'
          }
        }
      } else {
        if(this.offer_history_data[index] != undefined) {
          if (this.offer_history_data[index].star_flag == '0') {
            this.offer_history_data[index].star_flag = '1'
          } else {
            this.offer_history_data[index].star_flag = '0'
          }
        }
      }

      service
        .postService('updateSubscribe', {
          account_data : this.account_data,
          provider_id : val.id_member_goods_owner,
          mode : mode,
        })
        .then(rp => {
          if(rp.code == '200'){
            this.getSubscribeData()
            // setTimeout(() => {
            //   that.getOfferData()
            //   that.get_offer_has_been_submitted()
            //   that.getAcceptEstimateOfCarrier()
            // }, 100)
          }
        })
    },
    update_favorite(option, index){
      if(this.plans_row_data[index] != undefined) {
        if(this.plans_row_data[index].rawData.bookmark_flg == '0') {
          this.plans_row_data[index].rawData.bookmark_flg = '1'
        } else {
          this.plans_row_data[index].rawData.bookmark_flg = '0'
        }
      }
      service
        .postService('setBookmarkPlan', {
          mode:'carrier',
          id_carrier_tracking : option.rawData.id_carrier_tracking,
        })
        .then(rp => {
          if(rp.code == '200'){
            this.getPlansData()
          }
        })
    },
    // --- get data part ---
    getTruckType() {
      // get ประเภทรถบรรทุก
      return new Promise(resolve => {
        service.postService('getTruckType').then(rp => {
          // console.log(rp)
          this.dataTruckType = rp.data
          resolve(true)
          // console.log(this.dataTruckType)
        })
      })
    },
    getTruckTypeGroup() {
      return new Promise(resolve => {
        service.postService('getTruckTypeGroup').then(rp => {
          // console.log(rp)
          this.dataTruckTypeGroup = rp.data
          resolve(true)
          // console.log(this.dataTruckType)
        })
      })
    },
    findTruckTypeName(truck_type_id) {
      var truck_type_name = ''
      for (let index = 0; index < this.dataTruckType.length; index++) {
        const element = this.dataTruckType[index]
        if (truck_type_id == element.id_model) {
          if (truck_type_name != '') {
            truck_type_name += ', '
          }
          if (this.account_lang == 'en') {
            truck_type_name += element.name_model_en
          } else {
            truck_type_name += element.name_model_th
          }
        }
      }
      return truck_type_name
    },
    makeTruckTypeSelect() {
      var data_option = []
      for (var i = 0; i < this.dataTruckTypeGroup.length; i++) {
        var curr_group = this.dataTruckTypeGroup[i]
        data_option.push({
          id_model: 'model' + curr_group.id_group,
          id_group: curr_group.id_group,
          name: curr_group.name_group_th,
          $isDisabled: true,
        })

        this.dataTruckType.map(function(obj) {
          if (curr_group.id_group == obj.id_group) {
            data_option.push({
              id_model: obj.id_model,
              id_group: obj.id_group,
              display_img: false,
              name: obj.name_model_th,
            })
          }
        })

        // console.log(123)
      }
      this.option_truck_type = data_option
    },
    getPlansData(data_serach, func) {
      // get ข้อมูล แผนงานขนส่ง
      var that = this
      var mode = func
      if (mode == '' || mode == undefined) {
        mode = ''
      }
      service
        .postService('getCarrierPlan', {
          mode: mode,
          data_serach: data_serach,
          date_serach: this.search_date_start,
          period: moment().format('YYYY-MM-DD HH:mm'),
          sort_by_order: this.order_sel,
          sort_by_status: this.sort_by_status,
          user_data: this.account_data,
        })
        .then(rp => {
          // console.log('plan data' + rp.data)
          this.plans_data = rp.data
          setTimeout(() => {
            that.createRowOfPlans()
          }, 300)
        })
    },
    getOfferData(mode) {
      var that = this
      let mode_search = ''
      if(typeof mode !== 'undefined') {
        mode_search = mode
      }
      service.postService('getOffderData', { user_data: this.account_data }).then(rp => {
        this.plans_offer_raw_data = rp.data.plans_offer_data
        this.estimate_raw_data = rp.data.estimate_data
        setTimeout(() => {
          that.createRowOffer()
          if(mode_search == 'status') {
            that.bindingOfferStatus()
          }
        }, 300)
      })
    },
    getDataGoodsType() {
      // get ข้อมูล ประเภทสินค้า
      service.postService('getGoodsType').then(rp => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: '',
          }
          //this.dataGoodsType_master.push(goods_type_sel) // for insert data to db
          this.dataGoodsType_data.push(goods_type_sel)
          //if (this.account_lang != 'th') {
          //  this.goods_lang = false
          //}
        }
        // console.log(this.dataGoodsType_data2)
      })
    },
    // --- แปลงข้อมูลจาก raw data ---
    getGoodsTypeName(goodsTypeId) {
      // นำ goods_type_id มาหาชื่อของประเภทสินค้า
      var data_return = ''
      if (goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // console.log(res)
        // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          if (count_goods_type < 2) {
            for (let index = 0; index < this.dataGoodsType_data.length; index++) {
              const element = this.dataGoodsType_data[index]
              if (element.goods_type_id == goodsType_id) {
                if (data_return != '') {
                  data_return += ', '
                  data_return += element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                } else {
                  data_return = element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                }
              }
            }
            count_goods_type++
          } else {
            data_return += ', ...'
          }
        }
      } else {
        data_return = '-'
      }
      // console.log(data_return)
      return data_return
    },
    getGoodsTypeNameFull(goodsTypeId) {
      // นำ goods_type_id มาหาชื่อของประเภทสินค้า แบบเต็ม
      var data_return = ''
      if (goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // console.log(res)
        // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          for (let index = 0; index < this.dataGoodsType_data.length; index++) {
            const element = this.dataGoodsType_data[index]
            if (element.goods_type_id == goodsType_id) {
              if (data_return != '') {
                data_return += ', '
                data_return += element.goods_type_name_th
                if (!this.check_lang) {
                  data_return += element.goods_type_name_en
                }
              } else {
                data_return = element.goods_type_name_th
                if (!this.check_lang) {
                  data_return += element.goods_type_name_en
                }
              }
            }
          }
        }
      } else {
        data_return = '-'
      }
      // console.log(data_return)
      return data_return
    },
    getPlanStatus(status) {
      // เช็คสถานะของ raw data ของ แผนงานนั้นๆ จาก status
      var data_return = ''
      if (status == '0') {
        data_return = 'รอเสนองาน'
      } else if (status == '1') {
        data_return = 'รอเสนองาน'
      } else if (status == '2') {
        data_return = 'รอดำเนินการ'
      } else if (status == '3') {
        data_return = 'อยู่ระหว่างขนส่ง'
      } else if (status == '4') {
        data_return = 'ดำเนินการเสร็จแล้ว'
      } else if (status == '5') {
        data_return = 'ปิดงาน'
      }
      return data_return
    },
    getDateTime(datetime) {
      // แปลงเวลาเป็น format สำหรับแสดงบนเว็บ
      var ck1 = datetime !== null && datetime != null && datetime != undefined
      var data_return = this.lang.no_data_time
      if (ck1) {
        data_return = moment(datetime).format('dd DD/MM/YYYY HH:mm')
      }
      return data_return
    },
    getPickupTime(time1, time2) {
      var dateData = global_func.Between2Date(time1, time2)
      var diffTime = dateData.diffTime / 1000 / 60 / 60
      var date_return = ''
      if (diffTime > 23.9997222222 && diffTime < 24) {
        date_return = moment(time1).format('dd DD/MM/YYYY') + ' ' + '(ตลอดทั้งวัน)'
      } else {
        date_return = moment(time1).format('dd DD/MM/YYYY HH:mm') + ' - ' + moment(time2).format('dd DD/MM/YYYY HH:mm')
      }
      return date_return
    },
    // --- sort data and binding part ---
    search_plan(func) {
      var mode = func
      var data_serach = ''
      // console.log(mode)
      if (mode == 'plan_code') {
        data_serach = this.search_plans_text
      } else if (mode == 'search_date') {
        data_serach = this.search_date_start
      }
      this.getPlansData(data_serach, mode)
    },
    findOfferProvider(provider_id) {
      var id = provider_id
      var array = this.plans_offer_raw_data
      var plan_data = this.plan_data_selcet
      var flag = '0'
      if (array !== undefined && array !== null) {
        for (let index = 0; index < array.length; index++) {
          const element = array[index]
          if (element.id_order_tracking == plan_data.rawData.id_order_tracking && element.provider_id == id) {
            flag = '1'
          }
        }
      }
      return flag
    },
    findProviderStatus(data) {
      let data_return = '1'
      let current_offer = data
      if(typeof current_offer.reject_flg !== 'undefined') { // เช็คว่าแผนมีการยื่นข้อเสนอหรือยัง สำหรับ tab ยื่นข้อเสนอ
        if(current_offer.reject_flg == '0') {
          if(current_offer.accept_flg == '1') {
            data_return = '1'
          } else {
            data_return = '2'
          }
        }else {
          data_return = '3'
        }
      } else { // สำหรับ tab เสนอราคา
        let array = this.estimate_raw_data
        let flg = 0
        for (let index = 0; index < array.length; index++) {
          if(typeof array[index][0] !== 'undefined') {
            const element = array[index][0]
            if(element.id_order == current_offer.id_order) { // เช็คว่าแผนมีข้อมูลในเสนอราคาไหม
              // console.log('element')
              // console.log(element)
              if(element.reject_flg == '0') {
                data_return = '2'
              } else {
                data_return = '3'
              }
              flg = 1
            }
          }
        }
        if(flg == 0) {
          data_return = '1'
        }
      }
      return data_return
    },
    bindingOfferStatus() {
      let sort_by_offer_status = this.sort_by_offer_status
      let search_offer_text = this.search_offer_text
      let offer_provider_data = this.offer_provider_data
      let data_array = []
      for (let index = 0; index < offer_provider_data.length; index++) {
        const element = offer_provider_data[index]
        // console.log(element)
        if(sort_by_offer_status != '0') {
          if(search_offer_text != '') {
            if(element.provider_status_code == sort_by_offer_status && element.provider_name.indexOf(search_offer_text) != '-1') {
              data_array.push(element)
            }
          } else {
            if(element.provider_status_code == sort_by_offer_status) {
              data_array.push(element)
            }
          }
        } else {
          if(search_offer_text != '') {
            if(element.provider_name.indexOf(search_offer_text) != '-1') {
              data_array.push(element)
            }
          } else {
            data_array.push(element)
          }
        }
      }
      this.offer_provider_data = data_array
    },
    timeout_search_offer() {
      var that = this
      // console.log('ok');
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.getOfferData('status')
      }, 500)
    },
    bindingOfferHistory() {
      let sort_by_offer_history_status = this.sort_by_offer_history_status
      let search_offer_history_text = this.search_offer_history_text
      let offer_history_data = this.offer_history_data
      let data_array = []
      for (let index = 0; index < offer_history_data.length; index++) {
        const element = offer_history_data[index]
        // console.log(element)
        if(sort_by_offer_history_status != '0') {
          if(search_offer_history_text != '') {
            if(element.provider_status_code == sort_by_offer_history_status && element.member_goods_name.indexOf(search_offer_history_text) != '-1') {
              data_array.push(element)
            }
          } else {
            if(element.provider_status_code == sort_by_offer_history_status) {
              data_array.push(element)
            }
          }
        } else {
          if(search_offer_history_text != '') {
            if(element.member_goods_name.indexOf(search_offer_history_text) != '-1') {
              data_array.push(element)
            }
          } else {
            data_array.push(element)
          }
        }
      }
      this.offer_history_data = data_array
    },
    timeout_search_history_offer() {
      var that = this
      // console.log('ok');
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.get_offer_has_been_submitted('status')
      }, 500)
    },
    timeout_search_plans() {
      var that = this
      // console.log('ok');
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.search_plan('plan_code')
      }, 500)
    },
    // --- plan display part ---
    createRowOfPlans() {
      var ck1 = this.dataTruckType.length > 0
      var ck2 = this.plans_data.length > 0
      this.plans_row_data = []
      if (ck1 && ck2) {
        var rowPlanData = {}
        for (let index = 0; index < this.plans_data.length; index++) {
          const row = this.plans_data[index]
          // console.log(row)
          let pickup_time = this.getPickupTime(row.pickup_time_1, row.pickup_time_2)
          let vehicle_type_obj = row.vehicle_type_id.split('|')
          var truck_type_name = this.findTruckTypeName(vehicle_type_obj[0])
          var truck_type_group = this.dataTruckType[vehicle_type_obj[0]].id_group
          var status_n = this.getPlanStatus(row.carrier_status)
          if (vehicle_type_obj.length > 1) {
            truck_type_name = truck_type_name + ', ...'
          }
          // row.truck_data = []
          rowPlanData = {
            truck_type_group: truck_type_group,
            truck_type_name: truck_type_name,
            status_name: status_n,
            rawData: row,
          } 
          this.plans_row_data.push(rowPlanData)
          // console.log('this.plans_row_data',this.plans_row_data)
        }
        var urlParams = new URLSearchParams(window.location.search)
        if(urlParams.get('data') != null && this.url_params_search_flag){
          this.url_params_search_flag = false
          const found = this.plans_row_data.find(element => element.rawData.id_carrier_tracking == urlParams.get('data'))
          this.activePlansDisplay(found)
        }
        this.plans_data_status = true

        // console.log(this.plans_row_data)
      } else {
        this.plans_data_status = false
      }
    },
    backToPlanListDisplay() {
      this.plans_display_status = false
    },
    async activePlansDisplay(planData) {
      this.data_id_carrier = null
      this.data_id_order = null
      this.data_id_order = planData.rawData.last_t_carrier != null ? planData.rawData.last_t_carrier[0].id_order : []
      this.data_id_carrier = planData.rawData.id_carrier
  
      this.plan_data_selcet = planData
      this.plan_location = []
      // console.log(planData.rawData)

      this.plan_location[0] = {
        name: planData.rawData.depart_address1,
        date_appointment: planData.rawData.depart_date,
      }
      this.plan_location[1] = {
        name: planData.rawData.arrive_address1,
        date_appointment: planData.rawData.arrive_date,
      }
      var self = this
      setTimeout(function() {
        self.$refs.location_display.show(self.plan_location)
      }, 500)
      //depart_date

      this.plans_display_status = true
      
      if (planData.rawData.last_t_carrier != null) {
        //DISPLAY LIST OF E_STIMATE
        this.display_as_accept_estimate = true
        this.getAcceptEstimateOfCarrier()
      } else {
        //DISPLAY LIST OF OFFER
        this.display_as_accept_estimate = false
        await this.getOfferData()
        await this.get_offer_has_been_submitted()
      }
    },
    cilck_provider(row_index, type_provider) {
      let index = row_index

      if (this.offer_provider_data[index].active_status) {
        this.offer_provider_data[index].active_status = false
      } else {
        this.offer_provider_data[index].active_status = true
      }
    },
    provider_action(row_data) {
      var data = row_data
      for (let index = 0; index < this.service_provider_data.length; index++) {
        const element = this.service_provider_data[index]
        if (element.provider_id == data.provider_id) {
          if (this.service_provider_data[index].provider_plan_action_code == '1') {
            this.service_provider_data[index].provider_plan_action_code = '0'
          } else {
            this.service_provider_data[index].provider_plan_action_code = '1'
          }
        }
      }
    },
    // send action data
    sendPlanOffer(row_data, plan_data) {
      var data1 = row_data
      var data2 = plan_data
      var that = this
      service
        .postService('sendPlanOffer', {
          provider_data: data1,
          plan_data: data2,
        })
        .then(rp => {
          global_func.alertMessage('ยื่นข้อเสนอเรียบร้อย', '', 'success')
          setTimeout(function() {
            that.provider_action(row_data)
          }, 1500)
        })
    },
    delPlanOffer(row_data, plan_data) {
      var data1 = row_data
      var data2 = plan_data
      var that = this
      service
        .postService('delPlanOffer', {
          provider_data: data1,
          plan_data: data2,
        })
        .then(rp => {
          global_func.alertMessage('ยกเลิกการยื่นข้อเสนอเรียบร้อย', '', 'success')
          setTimeout(function() {
            that.provider_action(row_data)
          }, 1500)
        })
    },
    delPlanData(plan_data) {
      var planData = plan_data
      var that = this
      // console.log('test')
      // console.log(planData)
      service
        .postService('delPlanDataCarrier', {
          plan_data: planData,
        })
        .then(rp => {
          if(rp.code == '200') {
            global_func.alertMessage(that.lang.alert.del_plan, '', 'success')
            setTimeout(function() {
              that.backToPlanListDisplay()
              that.getPlansData()
              // that.delPlanDataFromRowDisplay(planData)
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    delPlanDataFromRowDisplay(planData) {
      var plan_data_sel_del = planData
      for (let index = 0; index < this.plans_row_data.length; index++) {
        const element = this.plans_row_data[index]
        if (element.rawData.id_order == plan_data_sel_del.id_order) {
          this.plans_row_data[index] = ''
        }
      }
    },
    createRowOffer() {
      this.service_provider_data = []
      this.offer_provider_data = []

      // console.log(123)
      var data = this.plans_offer_raw_data //this.service_provider_raw_data
      // data.push(this.service_provider_raw_data[0])
      this.dataGoodsType_data
      // var status_flg = '0'
      let data_sort = []
      let all_provider_id = []
      for (let index = 0; index < data.length; index++) {
        const curr_data = data[index]

        var expected_price = curr_data.expected_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        var good_type_name = this.getGoodsTypeName(curr_data.goods_type_id)
        let vehicle_type_obj = curr_data.vehicle_type_id.split('|')
        var truck_type_name = this.dataTruckType[vehicle_type_obj[0] - 1].name_model_th
        var vehicle_type_group_id = this.dataTruckType[vehicle_type_obj[0] - 1].id_group //vehicle_type_obj[0]
        var truck_type_group = this.dataTruckType[vehicle_type_obj[0]].id_group
        // var truck_type_name = this.findTruckTypeName(vehicle_type_obj[0])
        // var vehicle_type_group_id = this.findTruckTypeName(vehicle_type_obj[0])
        if (vehicle_type_obj.length > 1) {
          truck_type_name = truck_type_name + ', ...'
        }
        //order_period_display พฤ 20/05/21 13.40 น.

        var split_period = curr_data.order_period.split(' ')
        var year_period = split_period[0].split('-')[0]
        year_period = year_period.substr(year_period.length - 2, 2)

        var month_period = split_period[0].split('-')[1]
        var date_period = split_period[0].split('-')[2]

        var hour_period = split_period[1].split(':')[0]
        var minute_period = split_period[1].split(':')[1]

        var dt = new Date(curr_data.order_period)
        var list_short_date = [
          { th: 'อา', en: 'Sun' },
          { th: 'จ', en: 'Mon' },
          { th: 'อ', en: 'Tue' },
          { th: 'พ', en: 'Wed' },
          { th: 'พฤ', en: 'Thu' },
          { th: 'ศ', en: 'Fri' },
          { th: 'ส', en: 'Sat' },
        ]

        var order_period_display = list_short_date[dt.getDay()][localStorage.getItem('account_lang')] + '. ' + date_period + '/' + month_period + '/' + year_period + ' ' + hour_period + '.' + minute_period + 'น.'

        var split_other_service = curr_data.other_services.split('|')
        var other_service_display = ''
        for (var i = 0; i < split_other_service.length; i++) {
          for (var k = 0; k < this.data_service_etc.length; k++) {
            if (split_other_service[i] == this.data_service_etc[k].service_etc_id) {
              if (other_service_display != '') {
                other_service_display += ','
              }
              other_service_display += this.data_service_etc[k].name_th
            }
          }
        }

        var data_push = {
          id_order_tracking: curr_data.id_order_tracking,

          id_order: curr_data.id_order,
          id_member_goods_owner: curr_data.id_member,
          provider_id: curr_data.provider_id,
          provider_name: curr_data.mover_name,
          goods_type_id: '1', //element.goods_type_id
          provider_status_code: this.findProviderStatus(curr_data),
          provider_status_name: 'ไม่มีรถบริการในเวลาดังกล่าว',
          provider_tel: curr_data.mover_tel,
          provider_email: curr_data.mailaddr,
          mover_address1: '',
          provider_plan_action_code: '',
          star_score: curr_data.user_score.star_score,
          lover_score: curr_data.user_score.lover_score,
          star_flag : this.findStarFlag(curr_data.id_member),
          expected_price: expected_price,

          departure_address: curr_data.departure_address,
          departure_lat_lon: curr_data.departure_lat_lon,

          arrival_address: curr_data.arrival_address,
          arrival_lat_lon: curr_data.arrival_lat_lon,

          total_distance: curr_data.total_distance,
          good_type_id: curr_data.goods_type_id,
          good_type_name: good_type_name,
          question: curr_data.question,
          quantity: curr_data.quantity,
          truck_type_name: truck_type_name,
          vehicle_type_group_id: vehicle_type_group_id,
          other_services: curr_data.other_services,
          other_service_display: other_service_display,
          //truck_type_group: truck_type_group,
          created: curr_data.created,
          created_order: curr_data.created_order,
          order_period: curr_data.order_period,
          order_period_display: order_period_display,
          pickup_time_1: curr_data.pickup_time_1,
          pickup_time_2: curr_data.pickup_time_2,
          active_status: false,
        }
        data_sort.push(data_push)
        all_provider_id.push(curr_data.id_member)
      }
      this.offer_provider_data = this.bindingProviderStar(data_sort)
      this.getAllProfilePicture(all_provider_id, 2)
    },
    sendStartPlan(option) {
      var data = option
      var that = this
      service
        .postService('sendStatusPlan', {
          plan_data: data,
          mode: 'start_plan',
          account_data: this.account_data,
        })
        .then(rp => {
          if(rp.code == '200') {
            global_func.alertMessage('เริ่มแผนงานรถว่างเรียบร้อย', '', 'success')
            setTimeout(function() {
              that.plan_data_selcet.status_name = that.getPlanStatus('3')
            }, 500)
            setTimeout(function() {
              that.getPlansData()
            }, 1000)
            setTimeout(function() {
              that.activePlansDisplay(that.plan_data_selcet)
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }

        })
    },
    sendFinPlan(option) {
      var data = this.plan_data_selcet
      var that = this
      service
        .postService('sendStatusPlan', {
          plan_data: data,
          mode: 'fin_plan',
          account_data: this.account_data,
        })
        .then(rp => {
          if(rp.code == "200") {
            global_func.alertMessage('จบงานรถว่างเรียบร้อย', '', 'success')
            setTimeout(function() {
              that.plan_data_selcet.status_name = that.getPlanStatus('4')
            }, 500)
            setTimeout(function() {
              that.getPlansData()
            }, 1000)
            setTimeout(function() {
              that.activePlansDisplay(that.plan_data_selcet)
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    // ark method part
    askSendAlert(row_data, plan_data) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ “เสนองาน” ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendPlanOffer(row_data, plan_data)
        } else {
          return false
        }
      })
    },
    askDelPlan(plan_data) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ “ลบแผนงาน” ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.delPlanData(plan_data)
        } else {
          return false
        }
      })
    },
    askDelProviderOffer(row_data, plan_data) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ ยกเลิกการเสนองาน ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.delPlanOffer(row_data, plan_data)
        } else {
          return false
        }
      })
    },
    askStartPlan(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + '“เริ่มแผนงานรถว่าง”' + '<br />' + 'ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendStartPlan(this.plan_data_selcet)
        } else {
          return false
        }
      })
    },
    askFinPlan(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + '“จบงานรถว่าง”' + '<br />' + 'ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          // this.sendFinPlan(this.plan_data_selcet)
          this.openProofModal(this.plan_data_selcet)
        } else {
          return false
        }
      })
    },
    // confirm part
    open_modal_offer(param_offer) {
      //SET ORDER ID
      this.modal_offer.id_order = param_offer.id_order
      this.modal_offer.id_member_goods_owner = param_offer.id_member_goods_owner
      //SET MODAL DATA
      this.offer_upload_display = null
      this.offer_upload_file = null
      this.offer_correct_file = true

      this.offer_truck_type = null
      this.modal_offer.truck_quantity = ''
      this.modal_offer.cost = ''
      this.modal_offer.note = ''

      //if( this.offer_truck_type !== null){
      //  this.offer_truck_type = this.matchTruckTypeByID(this.offer_truck_type.id)
      //}

      this.$bvModal.show('modal-xl')
    },
    close_modal_offer() {
      //CLEAR ORDER ID
      this.modal_offer.id_order = null

      //OPEN CLOSE MODAL
      this.$bvModal.hide('modal-xl')
    },
    matchTruckTypeByID(id) {
      var choose = {}
      for (var i = 0; i < this.option.length; i++) {
        if (id == this.option[i].id) {
          choose = JSON.parse(JSON.stringify(this.option[i]))
        }
      }
      return choose
    },
    confirm_offer() {
      // console.log('id_carrier', this.plan_data_selcet.rawData.id_carrier)
      // console.log('order_id', this.modal_offer.id_order)
      // console.log('ประเภทรถ', this.offer_truck_type)
      // //ประเภทรภ : this.offer_truck_type.id_model 2
      // //กลุ่มประเภทรถ : this.offer_truck_type.id_group 1
      // console.log('จำนวนรถ', this.modal_offer.truck_quantity)
      // console.log('เสนอราคา', this.modal_offer.cost)
      // console.log('เลือกไฟล์', 'wait'), // console.log('หมายเหตุ', this.modal_offer.note)
      // console.log('123')

      if (this.offer_truck_type.id_model == null) {
        alert('โปรดเลือกประเภทรถ')
        return
      }

      var data = {
        id_order: this.modal_offer.id_order,
        price: this.modal_offer.cost,
        propose: this.modal_offer.note,
        id_member: this.plan_data_selcet.rawData.id_member,
        offer_file: this.offer_upload_file,
        id_carrier: this.plan_data_selcet.rawData.id_carrier,
        number_of_cars: this.modal_offer.truck_quantity,
        id_member_goods_owner: this.modal_offer.id_member_goods_owner,
        vehicle_type_id: this.offer_truck_type.id_model,
        vehicle_type_group_id: this.offer_truck_type.id_group,
        account_data: this.account_data,
        plan_raw_data: this.plan_data_selcet.rawData,
      }

      var that = this

      service.postService('saveToEstimate', data).then(async rp => {
        if (rp.message == 'save Data Success') {
          //AFTER SAVE
          await that.get_offer_has_been_submitted() //REFRESH HISTORY OFFER
          that.modal_offer.id_order = null //CLEAR VALUE
          that.$bvModal.hide('modal-xl') //CLOSE MODAL

          //DISPLAY ALERT
          Swal.fire({
            title: 'ยื่นข้อเสนอเรียบร้อย',
            text: '', //เราจะนำคุณไปหน้า “แผนงานรถว่าง”
            icon: 'success',
            //showCancelButton: true,
            confirmButtonColor: '#34c38f',
            // cancelButtonColor: '#FFFFFF',
            // cancelButtonTextColor: '#343C48',
            confirmButtonText: 'ปิด', //ไปหน้า “แผนงานรถว่าง”
            //cancelButtonText: 'ให้ฉันอยู่หน้านี้ต่อไป',
          }).then(result => {
            // console.log('OK')
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'บันทึกล้มเหลว',
            confirmButtonText: 'ปิด',
          })
        }
      })
    },
    handleFileUpload() {
      var curr_file = this.$refs.offerfile.files[0]
      var thisVue = this
      this.offer_upload_display = curr_file.name

      if (curr_file.type != 'image/png' && curr_file.type != 'image/jpeg' && curr_file.type != 'application/pdf') {
        this.offer_correct_file = false
      } else {
        this.offer_correct_file = true
      }

      let reader = new FileReader()

      //// console.log(rowFile)
      reader.addEventListener('load', () => {
        //reader.result
        var dataPush = {
          name: curr_file.name,
          data: reader.result,
          type: curr_file.type,
        }
        thisVue.offer_upload_file = dataPush
        // console.log('finish read')
      })
      reader.readAsDataURL(curr_file)

      // var file = this.$refs.offerfile.dropzone.files
      // for (let index = 0; index < file.length; index++) {
      //   let reader = new FileReader()
      //   const rowFile = file[index]
      //   reader.addEventListener(
      //     'load',
      //     () => {
      //       this.pdfFile[index] = reader.result
      //       // console.log(this.pdfFile)
      //     },
      //     false,
      //   )
      //   reader.readAsDataURL(rowFile)
      // }
    },
    open_modal_detail(data) {
      // console.log('data ja')
      //make detail
      var make_data = {
        id_order_tracking: data.id_order_tracking,
        created: data.created_order,
        order_period: data.order_period,
        // this.period_display = data.order_period_display
        good_type_name: data.good_type_name,
        quantity: data.quantity,
        question: data.question,
        arrival_address: data.arrival_address,
        arrival_lat_lon: data.arrival_lat_lon,
        departure_address: data.departure_address,
        departure_lat_lon: data.departure_lat_lon,
        total_distance: data.total_distance,
        vehicle_type_group_id: data.vehicle_type_group_id,
        truck_type_name: data.truck_type_name,
        other_service_display: data.other_service_display,
        pickup_time_1: data.pickup_time_1,
        pickup_time_2: data.pickup_time_2,
        expected_price: data.expected_price,
      }

      this.$refs.plandetail.bt_detail(make_data)
    },
    openProofModal(param_send) {
      this.$refs.modalProof.setProviderData(param_send)
    },
    //FUNCTION FOR OFFER HISTORY
    get_offer_has_been_submitted(mode) {
      var that = this
      let mode_search = ''
      if(typeof mode !== 'undefined') {
        mode_search = mode
      }
      service.postService('getOfferHasBeenSubmitted', { user_data: this.account_data }).then(rp => {
        that.service_offer_history = rp.data
        // that.createRowOfferHistory()
        setTimeout(() => {
          that.createRowOfferHistory()
          if(mode_search == 'status') {
            that.bindingOfferHistory()
          }
        }, 300)
      })
    },
    createRowOfferHistory() {
      var distinct_order = []
      //DISTINCT ORDER
      this.service_offer_history.map(function(o) {
        if (distinct_order.indexOf(o.id_order) == -1) {
          distinct_order.push(o.id_order)
        }
      })

      this.offer_history_data = []
      let data_sort = []
      let all_provider_id = []

      //EACH ORDER ID
      for (var index1 = 0; index1 < distinct_order.length; index1++) {
        var estimates = []
        this.service_offer_history.map(function(o) {
          if (o.id_order == distinct_order[index1]) {
            estimates.push(o)
          }
        })
        // console.log('me')

        var current_offer = null
        for (var index2 = 0; index2 < estimates.length; index2++) {
          var curr_data = estimates[index2]
          var price = curr_data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

          let vehicle_type_obj = curr_data.vehicle_type_id.split('|')
          var truck_type_name = this.dataTruckType[vehicle_type_obj[0] - 1].name_model_th
          var vehicle_type_group_id = this.dataTruckType[vehicle_type_obj[0] - 1].id_group //vehicle_type_obj[0]
          if (vehicle_type_obj.length > 1) {
            truck_type_name = truck_type_name + ', ...'
          }

          //var file_path = '/../file_upload_dtc_backhaul/images/offer_file/2021_8_6_11_24_36_105.pdf'
          var obj = {
            id_order_tracking: curr_data.id_order_tracking,
            id_order: curr_data.id_order,
            id_member_goods_owner: curr_data.id_member_goods_owner,
            // provider_id: curr_data.provider_id,
            price: price,
            // good_type_id: curr_data.goods_type_id,
            truck_type_name: truck_type_name,
            vehicle_type_group_id: vehicle_type_group_id,
            offer_created: curr_data.created,
            period: curr_data.period,
            number_of_cars: curr_data.number_of_cars,
            offer_filename: curr_data.offer_filename,
            reject_comment: curr_data.reject_comment,
            offer_file_type: curr_data.offer_file_type,
            offer_file_title: curr_data.offer_file_title,
          }

          if (index2 == 0) {
            var expected_price = curr_data.expected_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

            let order_vehicle_type_obj = curr_data.order_vehicle_type_id.split('|')
            var order_truck_type_name = this.dataTruckType[order_vehicle_type_obj[0] - 1].name_model_th
            var order_vehicle_type_group_id = this.dataTruckType[order_vehicle_type_obj[0] - 1].id_group

            if (order_vehicle_type_obj.length > 1) {
              order_truck_type_name = order_truck_type_name + ', ...'
            }

            var good_type_name = this.getGoodsTypeName(curr_data.goods_type_id)

            var split_other_service = curr_data.other_services.split('|')
            var other_service_display = ''
            for (var i = 0; i < split_other_service.length; i++) {
              for (var k = 0; k < this.data_service_etc.length; k++) {
                if (split_other_service[i] == this.data_service_etc[k].service_etc_id) {
                  if (other_service_display != '') {
                    other_service_display += ','
                  }
                  other_service_display += this.data_service_etc[k].name_th
                }
              }
            }

            let location_display = this.findTwoPoint(curr_data.departure_address, curr_data.arrival_address)

            current_offer = {
              id_order: curr_data.id_order,
              id_order_tracking: curr_data.id_order_tracking,

              member_goods_name: curr_data.member_goods_name,
              id_member_goods_owner: curr_data.id_member_goods_owner,
              star_score: curr_data.user_score.star_score,
              lover_score: curr_data.user_score.lover_score,
              star_flag : this.findStarFlag(curr_data.id_member_goods_owner),

              //order_period: curr_data.period,
              lastmod_order_offer: curr_data.lastmod_order_offer,
              created: curr_data.order_created, //created

              provider_status_code: this.findProviderStatus(curr_data),
              provider_status_name: 'ไม่มีรถบริการในเวลาดังกล่าว',
              provider_tel: curr_data.mover_goods_tel,
              order_mailaddr: curr_data.order_mailaddr,

              order_truck_type_name: order_truck_type_name,
              order_vehicle_type_group_id: order_vehicle_type_group_id,

              expected_price: curr_data.expected_price,
              quantity: curr_data.quantity,
              good_type_name: good_type_name,
              question: curr_data.question,

              departure_address: curr_data.departure_address,
              departure_lat_lon: curr_data.departure_lat_lon,

              arrival_address: curr_data.arrival_address,
              arrival_lat_lon: curr_data.arrival_lat_lon,

              pickup_time_1: curr_data.pickup_time_1,
              pickup_time_2: curr_data.pickup_time_2,

              location_display: location_display,

              total_distance: curr_data.total_distance,

              other_services: curr_data.other_services,
              other_service_display: other_service_display,

              active_status: false,
              active_offer_all: false,
            }
            current_offer.history = []
          }
          current_offer.history.push(obj)
          all_provider_id.push(curr_data.id_member_goods_owner)
        } //END index2
        data_sort.push(current_offer)
        
      } //END index1
      this.offer_history_data = this.bindingProviderStar(data_sort)
      let that = this
      // console.log(all_provider_id)
      setTimeout(function() {
        that.getAllProfilePicture(all_provider_id, 3)
      }, 500)
      // console.log('Me')
    },
    cilck_history_offer(row_index) {
      let index = row_index
      if (this.offer_history_data[index].active_status) {
        this.offer_history_data[index].active_status = false
      } else {
        this.offer_history_data[index].active_status = true
      }
    },
    display_all_reject(row_index) {
      let index = row_index
      if (this.offer_history_data[index].active_offer_all) {
        this.offer_history_data[index].active_offer_all = false
      } else {
        this.offer_history_data[index].active_offer_all = true
      }
    },
    open_modal_detail_historyoffer(data) {
      //make detail
      var make_data = {
        id_order_tracking: data.id_order_tracking,
        created: data.created,
        order_period: data.order_period,
        // this.period_display = data.order_period_display
        good_type_name: data.good_type_name,
        quantity: data.quantity,
        question: data.question,
        arrival_address: data.arrival_address,
        arrival_lat_lon: data.arrival_lat_lon,
        departure_address: data.departure_address,
        departure_lat_lon: data.departure_lat_lon,
        total_distance: data.total_distance,
        vehicle_type_group_id: data.order_vehicle_type_group_id,
        truck_type_name: data.order_truck_type_name,
        other_service_display: data.other_service_display,
        pickup_time_1: data.pickup_time_1,
        pickup_time_2: data.pickup_time_2,
        expected_price: data.expected_price,
      }
      this.$refs.plandetail.bt_detail(make_data)
    },
    alert_soon() {
      Swal.fire('Soon')
    },
    openFileTab(param) {
      if (param != null) {
        console.log(param)
        //var file = require('/../file_upload_dtc_backhaul/images/offer_file/2021_8_6_11_24_36_105.pdf') //pdf jpg
        // var file = require('/../file_upload_dtc_backhaul/images/offer_file/' + param) //pdf jpg
        // window.open(file, '_blank')
        let file_data = {
          filename: param.offer_filename,
          title: param.offer_file_title,
          filetype: param.offer_file_type,
        }
        service
          .postService('readFileDataService', {
            fileData: file_data,
          })
          .then(rp => {
            // console.log(rp)
            if(rp.data != '') {
              //var file = require('/../file_upload_dtc_backhaul/images/offer_file/2021_8_6_11_24_36_105.pdf') //pdf jpg
              // var file = require('/../file_upload_dtc_backhaul/images/offer_file/' + param.filename) //pdf jpg
              var file = rp.data
              var pdfWindow = window.open('')
              pdfWindow.document.write(
                '<iframe width=\'100%\' height=\'100%\' src=' + file + '></iframe>',
              )
            }
          })
      }
    },
    bt_company_detail(data) {
      var param_send = { provider_id: data.id_member_goods_owner }
      this.$refs.modalCompany.setProviderData(param_send)
      this.$bvModal.show('modal_company_detail')
    },
    show_cancel_plan_modal(data) {
      this.id_cancel_display = data.id_carrier_tracking
      //this.id_cancel_sel = { option_id: 1, name: "ยกเลิก" } //2
      this.$bvModal.show('modal-cancel')
      //

      // Swal.fire({
      //   icon: 'warning',
      //   title: '<strong>ยกเลิกแผนขนส่ง "BH00000001245"</strong> ', //<br>1
      //   input: 'select',
      //   inputOptions: {
      //     'Fruits': {
      //       apples: 'Apples',
      //       bananas: 'Bananas',
      //       grapes: 'Grapes',
      //       oranges: 'Oranges'
      //     },
      //     'Vegetables': {
      //       potato: 'Potato',
      //       broccoli: 'Broccoli',
      //       carrot: 'Carrot'
      //     },
      //     'icecream': 'Ice cream'
      //   },
      //   html:
      //     'You can use <b>bold text</b>, ' +
      //     '<a href="//sweetalert2.github.io">links</a> ' +
      //     'and other HTML tags',
      //   showCloseButton: true,
      //   showCancelButton: true,
      //   focusConfirm: false,
      //   confirmButtonText:
      //     '<i class="fa fa-thumbs-up"></i> Great!',
      //   confirmButtonAriaLabel: 'Thumbs up, great!',
      //   //cancelButtonText:'<i class="fa fa-thumbs-down"></i>',
      //   //cancelButtonAriaLabel: 'Thumbs down'
      // })
    },
    close_cancel_plan_modal() {
      this.$bvModal.hide('modal-cancel')
    },
    confirm_cancel_plan() {
      // console.log(this.id_order_cancel)
      // console.log(this.id_cancel_display)
      // console.log(this.id_cancel_sel)
    },
    getAcceptEstimateOfCarrier() {
      var that = this

      service
        .postService('getAcceptEstimateOfCarrier', {
          user_data: this.account_data,
          id_carrier: this.plan_data_selcet.rawData.id_carrier,
        })
        .then(rp => {
          this.es_raw_order_data = rp.orderData
          this.es_raw_estimate_data = rp.estimateData

          that.makeRowAcceptEstimateDisplay()
        })
    },
    makeRowAcceptEstimateDisplay() {
      //ORDER DATA
      this.es_goodmember_offer_data = []
      let id_member_goods_owner = []

      for (var i = 0; i < this.es_raw_order_data.length; i++) {
        let curr = this.es_raw_order_data[i]

        let expected_price = curr.expected_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        let good_type_name = this.getGoodsTypeName(curr.goods_type_id)

        let vehicle_type_obj = curr.vehicle_type_id.split('|')
        let vehicle_type_id = vehicle_type_obj[0]
        let vehicle_type_group_id = this.dataTruckType[vehicle_type_id - 1].id_group //vehicle_type_obj[0]
        var truck_type_name = this.dataTruckType[vehicle_type_id - 1].name_model_th
        let location_display = this.findTwoPoint(curr.departure_address, curr.arrival_address)

        var split_other_service = curr.other_services.split('|')
        var other_service_display = ''
        for (let so_index = 0; so_index < split_other_service.length; so_index++) {
          for (var k = 0; k < this.data_service_etc.length; k++) {
            if (split_other_service[so_index] == this.data_service_etc[k].service_etc_id) {
              if (other_service_display != '') {
                other_service_display += ','
              }
              other_service_display += this.data_service_etc[k].name_th
            }
          }
        }
        // console.log('curr',curr)
        // curr.truck_data = []
        // console.log('object')
        // console.log(curr)
        let provider_status_name = this.getPlanStatus(curr.order_status)
        let obj = {
          id_order_tracking: curr.id_order_tracking,
          id_member_goods_owner: curr.id_member,
          provider_name: curr.member_goods_name, //"บริษัท เบทเตอร์ เฟรท แอนด์ทรานสปอร์ด จำกัด",
          star_score: curr.user_score.star_score,
          lover_score: curr.user_score.lover_score,
          star_flag: this.findStarFlag(curr.id_member),
          order_period: '2021-08-17 23:59:59',
          created: curr.created, //"2021-08-17 23:59:59",
          provider_status_name: provider_status_name,
          provider_tel: curr.mover_tel, //"02-777-5555",
          provider_email: curr.mailaddr, //"contact.work@jcd.com",
          vehicle_type_id: vehicle_type_id, //3,
          truck_type_name: truck_type_name,
          vehicle_type_group_id: vehicle_type_group_id, // 1,
          //expected_price: "1,800",
          number_of_cars: 0,
          price: expected_price, // "1,800",
          good_type_name: good_type_name,
          question: curr.question,
          quantity: curr.quantity,
          location_display: location_display,
          arrival_address: curr.arrival_address,
          arrival_lat_lon: curr.arrival_lat_lon,
          departure_address: curr.departure_address,
          departure_lat_lon: curr.departure_lat_lon,

          pickup_time_1: curr.pickup_time_1,
          pickup_time_2: curr.pickup_time_2,

          other_service_display: other_service_display,
          propose: '',
          total_distance: curr.total_distance,
          active_status: true,
          rawData: curr,
        }
        this.es_goodmember_offer_data.push(obj)
        id_member_goods_owner.push(curr.id_member)
      }

      let that = this
      // console.log(id_member_goods_owner)
      setTimeout(function() {
        that.getAllProfilePicture(id_member_goods_owner, 1)
      }, 500)

      this.estimate_one = []
      this.estimate_all = []

      for (var index2 = 0; index2 < this.es_raw_estimate_data.length; index2++) {
        let curr_e = this.es_raw_estimate_data[index2]
        // console.log(curr_e)

        let price = curr_e.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        let good_type_name = this.getGoodsTypeName(curr_e.goods_type_id)

        let vehicle_type_obj = curr_e.vehicle_type_id.split('|')
        let vehicle_type_id = vehicle_type_obj[0]
        let vehicle_type_group_id = this.dataTruckType[vehicle_type_id].id_group //vehicle_type_obj[0]

        let obj_e = {
          provider_name: 'บริษัท เบทเตอร์ เฟรท แอนด์ทรานสปอร์ด จำกัด',
          star_score: '-',
          order_period: '2021-08-17 23:59:59',
          created: '2021-08-17 23:59:59',
          provider_status_name: 'รอดำเนินการ',
          provider_tel: '02-777-5555',
          provider_email: 'contact.work@jcd.com',
          vehicle_type_id: vehicle_type_id,
          vehicle_type_group_id: vehicle_type_group_id, //vehicle_type_group_id
          truck_type_name: '4 ล้อ - ตู้ทึบ',
          //expected_price: "1,800",
          number_of_cars: curr_e.number_of_cars,
          price: price,
          good_type_name: good_type_name,
          offer_filename: curr_e.offer_filename,
          offer_file_title: curr_e.offer_file_title,
          offer_file_type: curr_e.offer_file_type,
          quantity: '50',
          arrival_address: 'กรุงเทพมหานคร',
          departure_address: 'กรุงเทพมหานคร',
          propose: curr_e.propose,
          total_distance: '38',
          active_status: true,
          rawData: curr_e,
        }
        
        if (index2 == 0) {
          this.estimate_one.push(obj_e)
        }

        this.estimate_all.push(obj_e)
      }

      this.estimate_display_type = 'one'
      this.p_estimate_list = this.estimate_one
    },
    open_modal_detail_accepted(data) {
      //make detail
      var make_data = {
        id_order_tracking: data.id_order_tracking,
        created: data.created,
        order_period: data.order_period,
        // this.period_display = data.order_period_display
        good_type_name: data.good_type_name,
        quantity: data.quantity,
        question: data.question,
        arrival_address: data.arrival_address,
        arrival_lat_lon: data.arrival_lat_lon,
        departure_address: data.departure_address,
        departure_lat_lon: data.departure_lat_lon,
        total_distance: data.total_distance,
        vehicle_type_group_id: data.vehicle_type_group_id,
        truck_type_name: data.truck_type_name,
        other_service_display: data.other_service_display,
        pickup_time_1: data.pickup_time_1,
        pickup_time_2: data.pickup_time_2,
        expected_price: data.price,
      }
      this.$refs.plandetail.bt_detail(make_data)
    },
    switch_accept_list() {
      //CHANGE TO ALL
      if (this.estimate_display_type == 'one') {
        this.estimate_display_type = 'all'
        this.p_estimate_list = this.estimate_all
      }
      //CHANGE TO ONE
      else {
        this.estimate_display_type = 'one'
        this.p_estimate_list = this.estimate_one
      }
    },
    findTwoPoint(p1, p2) {
      let point1_split = []
      let point2_split = []
      let data_return = ''
      if (p1 != undefined && p1 != null) {
        let point1 = p1.trim()
        point1_split = point1.split(' ')
        if (point1_split.length > 0) {
          let ck_str = point1_split[point1_split.length - 1]
          let index = 1
          if (!isNaN(ck_str)) {
            index++
          }
          let prov = point1_split[point1_split.length - index]
          let prov_split = prov.split('.')
          if (prov_split.length > 1) {
            data_return += prov_split[1]
          } else {
            data_return += prov_split[0]
          }
        }
      }
      if (p2 != undefined || p2 != null) {
        let point2 = p2.trim()
        point2_split = point2.split(' ')
        if (point2_split.length > 0) {
          let ck_str = point2_split[point2_split.length - 1]
          if (data_return != '') {
            data_return += ' - '
          }
          let index = 1
          if (!isNaN(ck_str)) {
            index++
          }
          let prov = point2_split[point2_split.length - index]
          let prov_split = prov.split('.')
          if (prov_split.length > 1) {
            data_return += prov_split[1]
          } else {
            data_return += prov_split[0]
          }
        }
      }
      return data_return
    },
    routersClick(link) {
      // eslint-disable-next-line no-undef
      // bank
      // this.$router.push({ path: link })
      this.$router.push({ path: link + `/?data=${this.plan_data_selcet.rawData.id_carrier_tracking}` })
    },
    routersClick2(link) {
      // eslint-disable-next-line no-undef
      // bank
      // this.$router.push({ path: link })
      this.$router.push({ path: link })
    },
    askCancelPlan(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + 'ยกเลิกงานนี้' + '<br />' + 'ใช่หรือไม่' + '<br /><br />' + '*** การยกเลิกงานอาจจะมีค่าปรับเกิดขึ้น<br />ขึ้นอยู่กับผู้ให้บริการขนส่ง',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendCancelPlan(option)
        } else {
          return false
        }
      })
    },
    sendCancelPlan(estimate) {
      // console.log(estimate)
      var that = this
      service
        .postService('sendCancelPlanCarrier', {
          estimate_data: estimate,
          account_data: this.account_data,
        })
        .then(async (rp) => {
          // console.log(rp)
          if(rp.code == "200") {
            global_func.alertMessage(that.lang.alert.cancel, '', 'success')
            // that.changeDisplayAfterCancelPlan()
            await this.delete_truck(estimate.rawData.id_carrier)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    changeDisplayAfterCancelPlan() {
      // console.log('func change')
      window.location.reload()
      // this.display_as_accept_estimate = false
      // this.plan_data_selcet.rawData.carrier_status = '1'
      // this.plan_data_selcet.status_name = this.getPlanStatus('1')
      // setTimeout(function() {
      //   // that.getPlansData()
      //   that.display_as_accept_estimate = false
      // }, 1000)
      // setTimeout(function() {
      //   that.getOfferData()
      //   that.get_offer_has_been_submitted()
      // }, 1500)
    },
    getSubscribeData() {
      service
        .postService('getSubscribeData', {
          account_data : this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.status == 'ok') {
            this.subscribe_data = []
            this.subscribe_data = rp.data
          }
          // console.log(this.subscribe_data)
        })
    },
    findStarFlag(id_member) {
      let subscribe_data = this.subscribe_data
      let flag = '0'
      for (let index = 0; index < subscribe_data.length; index++) {
        const element = subscribe_data[index]
        if(element.provider_id == id_member) {
          flag = '1'
        }
      }
      return flag
    },
    bindingProviderStar(data) {
      let provider_data = data
      let provider_data_1 = []
      let provider_data_2 = []
      let provider_data_return = []

      // star first
      for (let index = 0; index < provider_data.length; index++) {
        const element = provider_data[index]
        if(element.star_flag == '1') {
          provider_data_1.push(element)
        } else {
          provider_data_2.push(element)
        }
      }

      for (let index2 = 0; index2 < provider_data_1.length; index2++) {
        const element = provider_data_1[index2]
        provider_data_return.push(element)
      }

      for (let index3 = 0; index3 < provider_data_2.length; index3++) {
        const element = provider_data_2[index3]
        provider_data_return.push(element)
      }

      return provider_data_return
      
    },
    getAllProfilePicture(all_provider_id, mode) {
      let provider_id = [...new Set(all_provider_id)]
      // console.log(provider_id)
      service
        .postService('getAllProfilePicture', {
          id_member_data: provider_id,
        })
        .then(rp => {
          // console.log(rp)
          if(mode == 1) {
            this.setProfilePicture(rp.data)
          } else if(mode == 2){
            this.setProfilePicture2(rp.data)
          } else {
            this.setProfilePicture3(rp.data)
          }
          
        })
    },
    setProfilePicture(profile_picture_data) {
      let temp_es_goodmember_offer_data = this.es_goodmember_offer_data
      this.es_goodmember_offer_data = []
      for (let index = 0; index < temp_es_goodmember_offer_data.length; index++) {
        let element = temp_es_goodmember_offer_data[index]
        // console.log(element)
        const blob = profile_picture_data.filter((e) => {
          return e.id_member == element.id_member_goods_owner
        })
        element.pic_profile = blob[0].pic_profile
        this.es_goodmember_offer_data.push(element)
      }
    },
    setProfilePicture2(profile_picture_data) {
      let temp_offer_provider_data = this.offer_provider_data
      this.offer_provider_data = []
      for (let index = 0; index < temp_offer_provider_data.length; index++) {
        let element = temp_offer_provider_data[index]
        // console.log(element)
        const blob = profile_picture_data.filter((e) => {
          return e.id_member == element.id_member_goods_owner
        })
        element.pic_profile = blob[0].pic_profile
        this.offer_provider_data.push(element)
      }
    },
    setProfilePicture3(profile_picture_data) {
      let temp_offer_history_data = this.offer_history_data
      this.offer_history_data = []
      for (let index = 0; index < temp_offer_history_data.length; index++) {
        let element = temp_offer_history_data[index]
        // console.log(element)
        const blob = profile_picture_data.filter((e) => {
          return e.id_member == element.id_member_goods_owner
        })
        element.pic_profile = blob[0].pic_profile
        this.offer_history_data.push(element)
      }
    },
  },
}
</script>

<template>
  <Layout>
    <b-row>
      <b-col>
        <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
          <div v-if="plans_display_status" class="col-main-menu summary-of-plan" :class="{ active: plans_display_status }">
            <div class="card" style="">
              <b-row>
                <b-col>
                  <div class="card-body" style="">
                    <div style="padding: 10px 10px 0 10px;" class="font-size-16">
                      <b-row class="col-margin-bot-1">
                        <b-col lg="3">
                          <span style="margin-top:5px;" class="d-flex justify-content-left btn_back_blue" @click="backToPlanListDisplay()"><i class="ri-arrow-left-line" style="margin-right:5px;" />ย้อนกลับ</span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1">
                        <b-col lg="11">
                          <span style="font-size:20px;">
                            <b>{{ lang.plans_detail_display.title }}</b>
                          </span>
                        </b-col>
                        <b-col lg="1" class="d-flex align-items-center">
                          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <img src="@/assets/images/plans_display/menu@2x.png" width="28px" height="9" />
                              <!-- <i class="mdi mdi-chevron-down" /> -->
                            </template>
                            <b-dropdown-item @click="askDelPlan(plan_data_selcet.rawData)">ลบแผนงาน</b-dropdown-item>
                            <!-- <b-dropdown-item @click="show_cancel_plan_modal(plan_data_selcet.rawData)">ยกเลิกแผนงานขนส่ง</b-dropdown-item> -->
                          </b-dropdown>
                        </b-col>
                      </b-row>
                      <simplebar class="card-body" style="height:calc(100vh - 250px); padding: 0px 20px 0px 0px;font-size:15px;">
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span>
                              <b>{{ lang.plans_detail_display.code }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.rawData.id_carrier_tracking }}
                              <!-- id_order_tracking -->
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span>
                              <b>{{ lang.plans_detail_display.date_create }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.rawData.created | moment('dd DD/MM/YYYY HH:mm') }}
                            </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span> <b>{{ lang.plans_detail_display.status }} :</b> </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.status_name }}
                            </span>
                            <span v-if="plan_data_selcet.rawData.carrier_status < 2" class="font-color-red"> ( เสนอราคาถึงวันที่ {{ plan_data_selcet.rawData.period | moment('dd DD/MM/YYYY') }} ) </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans-row-data-content">
                              <b>{{ lang.plans_detail_display.goods_type }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.goods_type_name_full }}
                            </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span>
                              <b>{{ lang.plans_detail_display.location }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <!-- DEPARTURE LOCATION AND DESTINATION LOCATION -->
                            <DisplayLocation ref="location_display" :arrlocation="plan_location" />
                            <!--  -->
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span>
                              <b>{{ lang.plans_detail_display.map }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="btn_back_blue">
                              <!-- {{ lang.plans_detail_display.map_open }}123 -->
                              <ModalMap :obj="plan_data_selcet.rawData" />
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span>
                              <b>{{ lang.plans_detail_display.total_distance }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue"> {{ plan_data_selcet.rawData.total_distance }} {{ lang.plans_detail_display.km }} </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span>
                              <b>{{ lang.plans_detail_display.truck_type }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="">
                              <img v-if="plan_data_selcet.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" width="40px" style="margin-right: 20px" />
                              {{ plan_data_selcet.truck_type_name }}
                            </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span>
                              <b>จำนวนรถ :</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span v-if="plan_data_selcet.rawData.number_of_cars != '' && plan_data_selcet.rawData.number_of_cars !== null" class="font-color-blue"> {{ plan_data_selcet.rawData.number_of_cars }} คัน </span>
                            <span v-else class="font-color-blue">
                              -
                            </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span>
                              <b>น้ำหนักที่บรรทุกได้ :</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue"> {{ plan_data_selcet.rawData.payload }} ตัน </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span>
                              <b>หมายเหตุ :</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span v-if="plan_data_selcet.rawData.note != '' && plan_data_selcet.rawData.note !== null" class="plans-row-data-content font-color-blue">
                              {{ plan_data_selcet.rawData.note }}
                            </span>
                            <span v-else>
                              -
                            </span>
                          </b-col>
                        </b-row>
                      </simplebar>
                    </div>
                  </div>
                </b-col>

                <!-- ส่วนหน้าต่าง ผู้ให้บริการ -->
                <b-col>
                  <!-- ACCEPTED LIST OF ESTIMATE -->
                  <div v-if="display_as_accept_estimate" class="card-body" style=" padding 1.25rem; margin-right: 0.6rem;">
                    <div style="padding: 10px 10px 0 10px;">
                      <!-- <span class="font-size-20 font-weight-bold">เจ้าของสินค้า</span> -->
                      <!-- <span class="d-none d-sm-inline-block topnav-text">เจ้าของสินค้า</span> -->
                      <nav>
                        <b-tabs justified nav-class="nav-tabs-custom" align="left">
                          <b-tab :active="false">
                            <!-- :active="true" -->
                            <template v-slot:title>
                              <div style="width:100%; text-align: left;">
                                <span class="font-size-20 font-weight-bold" style="color:black;">เจ้าของสินค้า</span>
                              </div>
                            </template>
                          </b-tab>
                        </b-tabs>
                      </nav>
                      <template>
                        <simplebar class="service-provider-display" style="height:calc(100vh - 250px); background: #F7F7F7 0% 0% no-repeat padding-box;">
                          <div role="tablist">
                            <b-card v-for="(option, index) in es_goodmember_offer_data" :key="index" no-body class="mb-1 shadow-none carrier-accept-estimate-row">
                              <div>
                                <b-row class="col-margin-bot-1">
                                  <div class="d-flex align-items-center">
                                    <!-- <img src="@/assets/images/plans_display/star_empty.png" width="24px" height="24px" style="margin-right: 1rem; margin-left: 0.6rem" /> -->
                                    <i :class="option.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="option.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;margin-right: 1rem; margin-left: 0.6rem' : 'cursor:pointer;font-size:25px;margin-right: 1rem; margin-left: 0.6rem'" @click="updateStar(option, index, 'goodmember_offer_data')" />
                                    <img v-if="option.pic_profile" :src="option.pic_profile" width="40px" height="40px" class="rounded-circle avatar-sm" />
                                    <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" width="40px" height="40px" class="rounded-circle avatar-sm" />
                                  </div>
                                  <b-col lg="10" style="margin-left: 1rem; cursor:pointer">
                                    <b-row>
                                      <b-col>
                                        <span class="font-size-16">
                                          {{ option.provider_name }}
                                        </span>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="d-flex align-items-center">
                                        <b-row>
                                          <b-col>
                                            <span class="font-size-14" style="margin-right: 0.5rem">
                                              ผู้สนใจ/Rating :
                                            </span>
                                            <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" />
                                            <span class="font-size-14" style="margin-right: 0.5rem"> ( {{ option.star_score }} ) </span>
                                            <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                                            <span class="font-size-14"> ( {{ option.lover_score }} ) </span>
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <img src="@/assets/images/plans_display/calendar@2x.png" width="16px" height="16px" style="margin-right: 5px" />
                                        <span>
                                          {{ option.created | moment('dd DD/MM/YYYY HH:mm') }}
                                        </span>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </div>
                              <b-row class="col-margin-bot-1">
                                <b-col lg="6" class="font-size-16">
                                  <span> สถานะ : </span>
                                  <span style="color: #0098D1"> {{ option.provider_status_name }} </span>
                                  <!-- <span v-if="option.provider_status_code == 1" style="color: #0098D1"> รอการตอบรับ </span> -->
                                  <!-- <span v-else-if="option.provider_status_code == 2" style="color: #0098D1"> ยื่นข้อเสนอใหม่ </span> -->
                                </b-col>
                                <b-col lg="5" style="margin-left: 2.3rem">
                                  <span class="font-size-16">
                                    เบอร์ติดต่อ : <span style="color: #0098D1;">{{ option.provider_tel }} </span>
                                  </span>
                                </b-col>
                              </b-row>
                              <b-row class="col-margin-bot-1">
                                <b-col lg="6">
                                  <span class="font-size-16">
                                    อีเมล : <span style="color: #0098D1;"> {{ (option.provider_email != NULL && option.provider_email != 'null' && option.provider_email != undefined) ? option.provider_email : ' - ' }} </span>
                                  </span>
                                </b-col>
                                <b-col lg="5" style="margin-left: 2.3rem">
                                  <span class="font-size-16">ข้อมูลบริษัท : </span>
                                  <span style="text-decoration: underline; cursor:pointer;" @click="bt_company_detail(option)">รายละเอียด</span>
                                </b-col>
                              </b-row>

                              <!-- START ACCEPT DETAIL -->
                              <div style="background: #F2F9FC;" class="col-margin-bot-1">
                                <b-col lg="12">
                                  <div style="padding: 20px;">
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="6" class="font-size-16 font-weight-bold">
                                        <img v-if="option.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                                        <img v-else-if="option.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                                        <img v-else-if="option.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                                        <img v-else-if="option.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                                        <img v-else-if="option.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                                        <img v-else-if="option.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                                        {{ findTruckTypeName(option.vehicle_type_id) }}
                                      </b-col>
                                    </b-row>

                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="12" class="font-size-16">
                                        <span>
                                          ประเภทสินค้า :
                                        </span>
                                        <span class="font-color-blue">
                                          {{ option.good_type_name }}
                                        </span>
                                      </b-col>
                                    </b-row>

                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="12" class="font-size-16">
                                        จุดรับ - ส่งสินค้า :
                                        <span class="font-color-blue">
                                          {{ option.location_display }}
                                        </span>
                                      </b-col>
                                    </b-row>

                                    <b-row class="col-margin-bot-1" style="">
                                      <b-col lg="12" style="margin-bottom: 0.5rem">
                                        <button class="btn btn-lg " style="font-size:16px; color: #343C48; background: #FFFFFF; border: 1px solid #B0BAC4;" @click="open_modal_detail_accepted(option)">
                                          ดูแบบละเอียด
                                        </button>
                                        <!-- <PlanDetail :obj="option" />  -->
                                      </b-col>
                                    </b-row>
                                  </div>
                                </b-col>
                              </div>
                              <!-- END ACCEPT DETAIL-->
                              <hr />

                              <b-row class="col-margin-bot-1">
                                <b-col lg="12">
                                  <span class="font-size-20 font-weight-bold"> ข้อเสนอที่ตอบรับ </span>
                                </b-col>
                              </b-row>

                              <div v-for="(option2, index2) in p_estimate_list" :key="index2" style="background: #F2FCF9;" class="" :class="{ 'col-margin-bot-1': index2 == p_estimate_list.length - 1, 'col-margin-bot-2': index2 != p_estimate_list.length - 1 }">
                                <b-row>
                                  <b-col lg="12">
                                    <div style="padding: 20px;">
                                      <b-row class="col-margin-bot-1">
                                        <b-col lg="12">
                                          <span class="font-size-20 font-weight-bold"> เสนอครั้งที่ {{ estimate_all[index2].rawData.num_estimate }} </span>
                                          <span />
                                        </b-col>
                                      </b-row>

                                      <b-row class="col-margin-bot-1">
                                        <b-col lg="6" class="font-size-16 font-weight-bold">
                                          <img v-if="option2.vehicle_type_group_id == 1" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option2.vehicle_type_group_id == 2" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option2.vehicle_type_group_id == 3" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option2.vehicle_type_group_id == 4" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option2.vehicle_type_group_id == 5" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option2.vehicle_type_group_id == 6" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                                          {{ findTruckTypeName(option2.vehicle_type_id) }}
                                        </b-col>
                                        <b-col lg="5" class="font-size-16">
                                          <span>
                                            เสนอราคา :
                                          </span>
                                          <span class="font-size-20 font-weight-bold"> {{ option2.price }} {{ lang.bath }} </span>
                                          <span class="font-color-red" />
                                        </b-col>
                                      </b-row>

                                      <b-row class="col-margin-bot-1">
                                        <b-col lg="6" class="font-size-16">
                                          <span>
                                            จำนวนรถ :
                                          </span>
                                          <span class="font-color-blue"> {{ option2.number_of_cars }} {{ lang.car }} </span>
                                        </b-col>
                                        <b-col lg="5" class="font-size-16">
                                          ใบเสนอราคา :
                                          <a target="_blank" style="color:black; cursor:pointer;" @click="openFileTab(option2)">
                                            <u>{{ option2.offer_filename }} </u>
                                          </a>
                                        </b-col>
                                      </b-row>
                                      <b-row class="col-margin-bot-1">
                                        <b-col lg="12" class="font-size-16">
                                          <span>
                                            หมายเหตุ :
                                          </span>
                                          <span class="font-color-blue">
                                            {{ option2.propose }}
                                          </span>
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>

                              <!-- <b-row class="col-margin-bot-1">
                                <b-col lg="12" @click="switch_accept_list()">
                                  <u>ข้อเสนอทั้งหมด</u>
                                </b-col>
                              </b-row> -->

                              <!-- 
                              <div class="offer-provider-row-body" :class="{ active: estimate_data[index].active_status }">
                                
                                <b-row style="background: #F7F7F7;" class="col-margin-bot-2">
                                  <b-col lg="12">
                                    <div style="padding: 20px;">
                                      <b-row class="col-margin-bot-1">
                                        <b-col lg="12">
                                          <span class="font-size-20 font-weight-bold">
                                            เสนอครั้งที่ 1
                                          </span>
                                          <span>
                                            
                                          </span>
                                        </b-col>
                                      </b-row>
                                      
                                      <b-row class="col-margin-bot-1">
                                        <b-col lg="6" class="font-size-16 font-weight-bold">
                                          <img v-if="option.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                                          <img v-else-if="option.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                                          {{ findTruckTypeName(option.vehicle_type_id) }}
                                        </b-col>
                                        <b-col lg="5" class="font-size-16">
                                          <span>
                                            เสนอราคา : 
                                          </span>
                                          <span class="font-size-20 font-weight-bold">
                                            {{ option.price }} {{ lang.bath }}
                                          </span>
                                          <span class="font-color-red">
                                            
                                          </span>
                                        </b-col>
                                      </b-row>

                                      <b-row class="col-margin-bot-1">
                                        <b-col lg="6" class="font-size-16">
                                          <span>
                                            จำนวนรถ : 
                                          </span>
                                          <span class="font-color-blue">
                                            {{ option.number_of_cars }} {{ lang.car }}
                                          </span>
                                        </b-col>
                                        <b-col lg="5" class="font-size-16">
                                          ใบเสนอราคา :
                                        </b-col>
                                      </b-row>
                                      <b-row class="col-margin-bot-1">
                                        <b-col lg="12" class="font-size-16">
                                          <span>
                                            หมายเหตุ : 
                                          </span>
                                          <span class="font-color-blue">
                                            {{ option.propose }}
                                          </span>
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </b-col>
                                </b-row>
                                
                              </div>
                              -->
                              <hr v-if="plan_data_selcet.rawData.carrier_status != '5'" />

                              <b-row v-if="plan_data_selcet.rawData.carrier_status != '5'" class="col-margin-bot-1">
                                <b-col lg="6">
                                  <div class="font-size-20 font-weight-bold"> รถที่ให้บริการ </div>
                                </b-col>
                                <b-col lg="6">
                                  <div v-show="plan_data_selcet.rawData.carrier_status <= 2" style="float:right">
                                    <img v-if="plan_data_selcet.rawData.truck_data != undefined && plan_data_selcet.rawData.truck_data.length > 0" src="../../../assets/images/imgbank/edit.png" width="20px" style="margin-right: 20px;cursor:pointer;" @click="addTruck(plan_data_selcet.rawData.truck_data,1)" />
                                  </div>
                                </b-col>
                              </b-row>
                              <div v-if="plan_data_selcet.rawData.truck_data != undefined && plan_data_selcet.rawData.truck_data.length > 0" style="background: #F2FCF9;" class="" :class="{ 'col-margin-bot-1': index2 == p_estimate_list.length - 1, 'col-margin-bot-2': index2 != p_estimate_list.length - 1 }">
                                <div v-for="(data,ix) in plan_data_selcet.rawData.truck_data" :key="ix">
                                  <b-row>
                                    <b-col lg="12">
                                      <div style="padding: 20px;">
                                        <b-row class="col-margin-bot-1">
                                          <b-col lg="6" class="">
                                            <span class="font-size-16 font-weight-bold"> ทะเบียน  :</span> <span class="font-color-blue" style="font-size:14px;">{{ data.truck_num }}</span>
                                          </b-col>
                                          <b-col lg="6" class="">
                                            <span class="font-size-16 font-weight-bold" style="padding-right:5px;"> ประเภทรถ  :</span>
                                            <img v-if="data.id_group_vehicle == '1'" src="@/assets/images/truck_img/4_wheels.png" width="50px" style="margin-right: 20px" />
                                            <img v-else-if="data.id_group_vehicle == '2'" src="@/assets/images/truck_img/6_wheels.png" width="50px" style="margin-right: 20px" />
                                            <img v-else-if="data.id_group_vehicle == '3'" src="@/assets/images/truck_img/10_wheels.png" width="50px" style="margin-right: 20px" />
                                            <img v-else-if="data.id_group_vehicle == '4'" src="@/assets/images/truck_img/trailer2.png" width="50px" style="margin-right: 20px" />
                                            <img v-else-if="data.id_group_vehicle == '5'" src="@/assets/images/truck_img/trailer.png" width="50px" style="margin-right: 20px" />
                                            <img v-else-if="data.id_group_vehicle == '6'" src="@/assets/images/truck_img/tow_truck.png" width="50px" style="margin-right: 20px" />
                                            <span class="font-color-blue" style="font-size:14px;">{{ data.name_model_th }}</span>
                                          </b-col>
                                        </b-row>
                                      <!-- <b-row class="col-margin-bot-1">
                                        <b-col lg="6" class="">
                                          <span class="font-size-16 font-weight-bold"> เบอร์ติดต่อ  :</span> <span class="font-color-blue" style="font-size:14px;">081-204-3333</span>
                                        </b-col>
                                      </b-row> -->
                                      </div>
                                    </b-col>
                                  </b-row>
                                </div>
                              </div>
                              <div v-else-if="plan_data_selcet.rawData.carrier_status != '5'">
                                <div class="d-flex justify-content-center big_btn_blue" @click="addTruck('',0)">
                                  <span class="margin-top-bot-auto">ระบุข้อมูลรถ</span>
                                </div>
                              </div>
                              <hr />

                              <!-- bank -->
                              <!-- truck_data -->
                              <template v-if="option.rawData.order_status == '5'">
                                <b-row>
                                  <b-col lg="12">
                                    <center>
                                      <span class="font-color-red font-size-20 font-weight-bold"> ปิดงานแล้ว ({{ option.rawData.fin_plan_time | moment('dd DD/MM/YYYY HH:mm') }}) </span>
                                    </center>
                                  </b-col>
                                </b-row>
                              </template>
                              <template v-else>
                                <b-row style="margin-bottom: 1.1rem">
                                  <b-col v-if="plan_data_selcet.rawData.truck_data != undefined && plan_data_selcet.rawData.truck_data.length > 0">
                                    <div class="d-flex justify-content-center big_btn_blue" @click="routersClick('tracking')">
                                      <span class="margin-top-bot-auto">ติดตามรถ</span>
                                    </div>
                                  </b-col>
                                  <b-col v-else>
                                    <div class="d-flex justify-content-center big_btn_blue_dis">
                                      <span class="margin-top-bot-auto">ติดตามรถ</span>
                                    </div>
                                  </b-col>
                                  <!-- <b-col>
                                    <div class="d-flex justify-content-center big_btn_white_dis">
                                      <span class="margin-top-bot-auto">แชทกับผู้ให้บริการ (soon)</span>
                                    </div>
                                  </b-col> -->
                                </b-row>

                                <b-row>
                                  <b-col v-if="plan_data_selcet.rawData.truck_data == undefined || plan_data_selcet.rawData.truck_data.length == 0">
                                    <div class="d-flex justify-content-center big_btn_green_dis">
                                      <span class="margin-top-bot-auto">เริ่มแผนงาน</span>
                                    </div>
                                  </b-col>
                                  <b-col v-else-if="option.rawData.order_status == '2'" @click="askStartPlan(option)">
                                    <div class="d-flex justify-content-center big_btn_green">
                                      <span class="margin-top-bot-auto">เริ่มแผนงาน</span>
                                    </div>
                                  </b-col>
                                  <b-col v-else-if="option.rawData.order_status == '3'" @click="askFinPlan(option)">
                                    <div class="d-flex justify-content-center big_btn_green">
                                      <!-- <span class="margin-top-bot-auto">สิ้นสุดแผนงาน</span> -->
                                      <span class="margin-top-bot-auto">จบงานรถว่าง</span>
                                    </div>
                                  </b-col>
                                  <b-col v-else-if="option.rawData.order_status == '4'">
                                    <div class="d-flex justify-content-center big_btn_green_dis">
                                      <span class="margin-top-bot-auto">รอเจ้าของสินค้ายืนยัน</span>
                                    </div>
                                  </b-col>
                                  <b-col v-if="option.rawData.order_status == '2'">
                                    <div class="d-flex justify-content-center big_btn_red" @click="askCancelPlan(option)">
                                      <span class="margin-top-bot-auto">ยกเลิกงาน</span>
                                    </div>
                                  </b-col>
                                  <b-col v-else>
                                    <div class="d-flex justify-content-center big_btn_red_dis">
                                      <span class="margin-top-bot-auto">ยกเลิกงาน</span>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col lg="12">
                                    <br />
                                    <center>
                                      <span class="font-color-red font-size-16">
                                        กรณียกเลิกงานก่อนวันเริ่มงาน อาจมีการเรียกร้องค่าเสียหายจากผู้ให้บริการ
                                      </span>
                                    </center>
                                  </b-col>
                                </b-row>
                              </template>
                            </b-card>
                          </div>
                        </simplebar>
                      </template>
                    </div>
                  </div>
                  <!-- END ACCEPTED LIST OF ESTIMATE -->

                  <!-- OFFER LIST -->
                  <div v-else class="card-body" style="padding 1.25rem; margin-right: 0.6rem;">
                    <div style="padding: 10px 10px 0 10px;">
                      <nav>
                        <b-tabs v-model="active_tab" justified nav-class="nav-tabs-custom">
                          <b-tab :active="active_tab == 0">
                            <template v-slot:title>
                              <span class="d-none d-sm-inline-block topnav-text">
                                เสนองาน
                                <span style="color:red;">({{ offer_provider_data.length }})</span>
                              </span>
                            </template>
                          </b-tab>
                          <b-tab :active="active_tab == 1">
                            <template v-slot:title>
                              <span class="d-none d-sm-inline-block topnav-text">
                                ยื่นข้อเสนอ
                                <span style="color:red;">({{ offer_history_data.length }}) </span>
                              </span>
                            </template>
                          </b-tab>
                        </b-tabs>
                      </nav>
                      <!-- เสนองาน -->
                      <template v-if="active_tab == 0">
                        <div style="padding: 20px 20px 0px 20px; background: #F7F7F7 0% 0% no-repeat padding-box;">
                          <b-row>
                            <b-col lg="4">
                              <select v-model="sort_by_offer_status" class="form-control" @change="getOfferData('status')">
                                <option value="0" selected>สถานะทั้งหมด</option>
                                <option value="1">รอพิจารณา</option>
                                <option value="2">รอการตอบรับ</option>
                                <option value="3">รอยื่นข้อเสนอใหม่</option>
                              </select>
                            </b-col>
                            <b-col lg="8" class="col-margin-bot-1">
                              <div class="input-group prepend">
                                <b-form-input id="search_plans_text" v-model="search_offer_text" type="text" class="form-control custom-input-prepend" :placeholder="lang.placeholder.search_offer_text" @keyup="timeout_search_offer()" />
                                <div class="input-group-prepend custom-input-group-prepend" @click="getOfferData('status')">
                                  <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" /></span>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </div>

                        <simplebar class="service-provider-display" style="height:calc(100vh - 250px); background: #F7F7F7 0% 0% no-repeat padding-box;">
                          <div role="tablist">
                            <b-card v-for="(option, index) in offer_provider_data" :key="index" no-body class="mb-1 shadow-none service-provider-row" :class="{ active: option.active_status }">
                              <div>
                                <b-row class="col-margin-bot-1;">
                                  <b-col lg="2">
                                    <div class="d-flex align-items-center" style="width:100%;">
                                      <div style="margin:0 10px;margin-top:5px;">
                                        <i :class="option.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="option.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;' : 'cursor:pointer;font-size:25px;'" @click="updateStar(option, index, 'provider_data')" />
                                      </div>
                                      <div>
                                        <img v-if="option.pic_profile" :src="option.pic_profile" height="40px" class="rounded-circle avatar-sm" />
                                        <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" height="40px" class="rounded-circle avatar-sm" />
                                      </div>
                                    </div>
                                  </b-col>
                                  <b-col lg="5" style="cursor:pointer;" @click="cilck_provider(index, 'offer')">
                                    <b-row>
                                      <b-col>
                                        <span class="">
                                          <b>{{ option.provider_name }}</b>
                                        </span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col>
                                        <span class="font-size-14" style="margin-right: 0.5rem">
                                          ผู้สนใจ/Rating :
                                        </span>
                                        <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" />
                                        <span class="font-size-14" style="margin-right: 0.5rem"> ( {{ option.star_score }} ) </span>
                                        <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                                        <span class="font-size-14"> ( {{ option.lover_score }} ) </span>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col lg="5" style="cursor:pointer;" @click="cilck_provider(index, 'offer')">
                                    <b-row>
                                      &nbsp;
                                    </b-row>
                                    <b-row>
                                      <span>
                                        <img src="@/assets/images/plans_display/calendar.svg" style="height:17px;" />
                                        {{ option.created | moment('dd DD/MM/YYYY HH:mm') }}
                                      </span>
                                    </b-row>
                                  </b-col>
                                  <i class="fas fa-angle-down arrow" :class="{ down: option.active_status }" style="position: absolute; top:5px; right:0; cursor:pointer;" @click="cilck_provider(index, 'offer')" />
                                </b-row>
                                <b-row class="col-margin-bot-1">
                                  <b-col lg="6">
                                    <span style="">สถานะ : </span>
                                    <!-- <span style="font: normal normal normal 18px/27px Kanit;">{{ option.provider_status_code }}</span> -->
                                    <span v-if="option.provider_status_code == '1' || option.provider_status_code == '0'" style=" color: #0098D1">รอพิจารณา</span>
                                    <span v-else-if="option.provider_status_code == '2'" style=" color: #0098D1">รอการตอบรับ</span>
                                    <span v-else-if="option.provider_status_code == '3'" style=" color: #0098D1">รอยื่นข้อเสนอใหม่</span>
                                  </b-col>
                                  <b-col v-if="option.active_status" lg="6"> เบอร์ติดต่อ : {{ option.provider_tel }} </b-col>
                                </b-row>
                              </div>

                              <div class="service-provider-row-body" :class="{ active: option.active_status }">
                                <b-row class="col-margin-bot-1">
                                  <b-col lg="6" class="font-size-14">
                                    <span class="font-size-14"> อีเมล : {{ (option.provider_email != NULL && option.provider_email != 'null' && option.provider_email != undefined) ? option.provider_email : ' - ' }} </span>
                                  </b-col>
                                  <b-col lg="5" style=""> ข้อมูลบริษัท : <span style="text-decoration: underline; cursor:pointer;" @click="bt_company_detail(option)">รายละเอียด</span> </b-col>
                                </b-row>
                                <!-- -->
                                <div style="background: #F2F9FC 0% 0% no-repeat padding-box;">
                                  <b-row class="col-margin-bot-1" />
                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="6">
                                      <!-- <img src="@/assets/images/truck_img/4_wheels.png" style="width:70px; height: 34px;" /> -->
                                      <img v-if="option.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" style=" height: 30px;" />
                                      <img v-else-if="option.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" style=" height: 30px;" />
                                      <img v-else-if="option.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" style=" height: 30px;" />
                                      <img v-else-if="option.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" style=" height: 30px;" />
                                      <img v-else-if="option.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" style=" height: 30px;" />
                                      <img v-else-if="option.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" style=" height: 30px;" />
                                      <span style="padding-left: 5px; color: #343C48; font-size:16px;">&nbsp;&nbsp;{{ option.truck_type_name }}</span>
                                    </b-col>
                                    <b-col lg="6">
                                      จำนวนเงิน : <span style="padding-left: 5px; color: #343C48; font-size:16px;"> {{ option.expected_price }} </span>
                                    </b-col>
                                  </b-row>

                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="12">
                                      ประเภทสินค้า : <span style="color: #0098D1"> {{ option.good_type_name }} ({{ option.quantity }} ตัน) </span>
                                    </b-col>
                                    <!-- สินค้าอีเล็คทรอนิค(50 ตัน) -->
                                  </b-row>
                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="12">
                                      จุดรับ - ส่งสินค้า :
                                      <span style="color: #0098D1"> {{ option.departure_address }} - {{ option.arrival_address }} ({{ option.total_distance }} กิโลเมตร) </span>
                                      <!-- {{option.departure_address}} - {{option.arrival_address}} ({{option.total_distance}} กิโลเมตร)กรุงเทพมหานคร - กรุงเทพมหานคร (38.0 กิโลเมตร) {{ option.mover_address1 }} -->
                                    </b-col>
                                  </b-row>

                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col style="margin-bottom: 0.5rem">
                                      <button class="btn btn-lg " style="font-size:14px; color: #343C48; background: #FFFFFF; border: 1px solid #B0BAC4;" @click="open_modal_detail(option)">
                                        ดูแบบละเอียด
                                      </button>
                                      <!-- <PlanDetail :obj="option" />  -->
                                    </b-col>
                                  </b-row>
                                </div>
                                <b-row>
                                  <b-col lg="12">
                                    <button class="btn btn-success" style="width:100%;" @click="open_modal_offer(option)">ยื่นข้อเสนอ</button>
                                  </b-col>
                                  <!-- <b-col lg="6">
                                    <button class="btn btn-outline-info" style="width:100%;" @click="alert_soon">soon</button>
                                    แชทกับเจ้าของสินค้า
                                  </b-col> -->
                                </b-row>
                              </div>
                            </b-card>
                          </div>
                        </simplebar>
                      </template>
                      <!-- ส่วนหน้าต่าง ยื่นข้อเสนอ -->
                      <template v-else>
                        <div style="padding: 20px 20px 0px 20px; background: #F7F7F7 0% 0% no-repeat padding-box;">
                          <b-row>
                            <b-col lg="4">
                              <!-- 1234 -->
                              <select v-model="sort_by_offer_history_status" class="form-control" @change="get_offer_has_been_submitted('status')"> 
                                <option value="0" selected>สถานะทั้งหมด</option>
                                <option value="1">รอพิจารณา</option>
                                <option value="2">รอการตอบรับ</option>
                                <option value="3">ยื่นข้อเสนอใหม่</option>
                              </select>
                            </b-col>
                            <b-col lg="8" class="col-margin-bot-1">
                              <div class="input-group prepend">
                                <b-form-input id="search_offer_history_text" v-model="search_offer_history_text" type="text" class="form-control custom-input-prepend" :placeholder="lang.placeholder.search_offer_history_text" @keyup="timeout_search_history_offer()" />
                                <div class="input-group-prepend custom-input-group-prepend" @click="get_offer_has_been_submitted('status')">
                                  <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" /></span>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                        <simplebar class="service-provider-display" style="height:calc(100vh - 250px); background: #F7F7F7 0% 0% no-repeat padding-box;">
                          <div role="tablist">
                            <b-card v-for="(option, index) in offer_history_data" :key="index" no-body class="mb-1 shadow-none service-offer-history-row" :class="{ active: offer_history_data[index].active_status }">
                              <div>
                                <b-row class="col-margin-bot-1;">
                                  <b-col lg="2">
                                    <div class="d-flex align-items-center">
                                      <div style="margin:0 10px;margin-top:5px;">
                                        <i :class="option.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="option.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;' : 'cursor:pointer;font-size:25px;'" @click="updateStar(option, index)" />
                                      </div>
                                      <img v-if="option.pic_profile" :src="option.pic_profile" width="40px" height="40px" class="rounded-circle avatar-sm" />
                                      <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" width="40px" height="40px" class="rounded-circle avatar-sm" />
                                    </div>
                                  </b-col>
                                  <b-col lg="5" style="cursor:pointer;" @click="cilck_history_offer(index)">
                                    <b-row>
                                      <b-col>
                                        <span class="">
                                          <b>{{ option.member_goods_name }}</b>
                                          <!-- {{ option.id_order }} -->
                                        </span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col>
                                        <span class="font-size-14" style="margin-right: 0.5rem">
                                          ผู้สนใจ/Rating :
                                        </span>
                                        <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" />
                                        <span class="font-size-14" style="margin-right: 0.5rem"> ( {{ option.star_score }} ) </span>
                                        <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                                        <span class="font-size-14"> ( {{ option.lover_score }} ) </span>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col lg="5" style="cursor:pointer;" @click="cilck_history_offer(index)">
                                    <b-row>
                                      &nbsp;
                                    </b-row>
                                    <b-row>
                                      <span>
                                        <img src="@/assets/images/plans_display/calendar.svg" style="height:17px;" />
                                        {{ option.lastmod_order_offer | moment('dd DD/MM/YYYY HH:mm') }}
                                        <!--  -->
                                      </span>
                                    </b-row>
                                  </b-col>
                                  <!-- STATUS -->
                                  <!-- <img src="@/assets/images/plans_display/Ellipse 140.svg" style="width:35px; height: 17px; position: absolute; top: 18px;right: 27px;" /> -->
                                  <i class="fas fa-angle-down arrow" :class="{ down: option.active_status }" style="position: absolute; top:5px; right:0; cursor:pointer;" @click="cilck_history_offer(index)" />
                                </b-row>
                                <b-row class="col-margin-bot-1">
                                  <b-col lg="6">
                                    <span style="">สถานะ : </span> 
                                    <!-- <span style="font: normal normal 300 18px/22px Kanit; color: #0098D1"> {{ option.provider_status_code }} </span> -->
                                    <span v-if="option.provider_status_code == 1" style="color: #0098D1">รอพิจารณา</span>
                                    <span v-else-if="option.provider_status_code == 2" style="color: #0098D1">รอการตอบรับ</span>
                                    <span v-else-if="option.provider_status_code == 3" style="color: #0098D1">รอยื่นข้อเสนอใหม่</span>
                                  </b-col>

                                  <b-col v-if="option.active_status" lg="6"> เบอร์ติดต่อ : {{ option.provider_tel }} </b-col>
                                </b-row>
                              </div>

                              <div class="service-offer-history-row-body" :class="{ active: option.active_status }">
                                <b-row class="col-margin-bot-1">
                                  <b-col lg="6" class="font-size-16">
                                    <span class="font-size-16">
                                      อีเมล : <span style="color: #0098D1">{{ (option.order_mailaddr != 'null' && option.order_mailaddr != NULL && option.order_mailaddr != undefined) ? option.order_mailaddr : '-' }} </span>
                                    </span>
                                  </b-col>

                                  <b-col lg="5" style=""> ข้อมูลบริษัท : <span style="text-decoration: underline; cursor:pointer;" @click="bt_company_detail(option)">รายละเอียด</span> </b-col>
                                </b-row>

                                <div style="background: #F2F9FC 0% 0% no-repeat padding-box;">
                                  <b-row class="col-margin-bot-1" />
                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="6">
                                      <!-- <img src="@/assets/images/truck_img/4_wheels.png" style="width:70px; height: 34px;" /> -->
                                      <img v-if="option.order_vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" style="height: 30px;" />
                                      <img v-else-if="option.order_vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" style="height: 30px;" />
                                      <img v-else-if="option.order_vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" style="height: 30px;" />
                                      <img v-else-if="option.order_vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" style="height: 30px;" />
                                      <img v-else-if="option.order_vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" style="height: 30px;" />
                                      <img v-else-if="option.order_vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" style="height: 30px;" />
                                      <span style="padding-left: 5px; color: #343C48; font-size:16px;">&nbsp;&nbsp;{{ option.order_truck_type_name }}</span>
                                    </b-col>
                                    <b-col lg="6">
                                      จำนวนเงิน : <span style="padding-left: 5px; color: #343C48; font-size:16px;"> {{ option.expected_price }} </span>
                                    </b-col>
                                  </b-row>

                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="12">
                                      ประเภทสินค้า : <span style="color: #0098D1"> {{ option.good_type_name }} ({{ option.quantity }} ตัน) </span>
                                    </b-col>
                                    <!-- สินค้าอีเล็คทรอนิค(50 ตัน) -->
                                  </b-row>
                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="12">
                                      จุดรับ - ส่งสินค้า :
                                      <span style="color: #0098D1"> {{ option.location_display }} ({{ option.total_distance }} กิโลเมตร) </span>
                                      <!-- <span style="color: #0098D1"> {{ option.arrival_address }} - {{ option.departure_address }} ({{ option.total_distance }} กิโลเมตร)  </span>  -->
                                    </b-col>
                                  </b-row>

                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="12" style="margin-bottom: 0.5rem">
                                      <button class="btn btn-lg " style="font-size:14px; color: #343C48; background: #FFFFFF; border: 1px solid #B0BAC4;" @click="open_modal_detail_historyoffer(option)">
                                        ดูแบบละเอียด
                                      </button>
                                      <!-- <PlanDetail :obj="option" />  -->
                                    </b-col>
                                  </b-row>
                                </div>
                              </div>

                              <!-- option.history.length > 0 -->
                              <div v-if="option.history.length > 0 && option.active_status && !option.active_offer_all" class="service-offer-history-row-single-reject-body" :class="{ active: option.active_status }">
                                <div style="background:#F7F7F7 0% 0% no-repeat padding-box; padding-top:5px; padding-bottom:5px; margin-bottom:10px;">
                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="12">
                                      <span style="padding-left: 5px; color: #343C48; font-size:16px;"> <b>เสนอครั้งที่ {{ option.history.length }}</b> </span>
                                      ( {{ option.history[0].offer_created | moment('dd DD/MM/YYYY HH:mm') }} น.)
                                    </b-col>
                                  </b-row>
                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="6">
                                      <img v-if="option.history.length > 0 && option.history[0].vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" style="height: 30px;" />
                                      <img v-else-if="option.history.length > 0 && option.history[0].vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" style="height: 30px;" />
                                      <img v-else-if="option.history.length > 0 && option.history[0].vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" style="height: 30px;" />
                                      <img v-else-if="option.history.length > 0 && option.history[0].vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" style="height: 30px;" />
                                      <img v-else-if="option.history.length > 0 && option.history[0].vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" style="height: 30px;" />
                                      <img v-else-if="option.history.length > 0 && option.history[0].vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" style="height: 30px;" />
                                      <span style="padding-left: 5px; color: #343C48; font-size:16px;"> {{ option.history[0].truck_type_name }} </span>
                                    </b-col>
                                    <b-col lg="6">
                                      เสนอราคา: <span style="padding-left: 5px; color: #343C48; font-size:16px;">{{ option.history[0].price }} บาท </span>
                                      <!-- <label style="color:#33C38F"> ( {{ option.history[0].discount }} บาท) </label> -->
                                    </b-col>
                                  </b-row>
                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="6">
                                      จำนวนรถ: <span style="color: #0098D1">{{ option.history[0].number_of_cars }} คัน </span>
                                    </b-col>
                                    <b-col lg="6">
                                      ใบเสนอราคา:
                                      <a target="_blank" style="color:black; cursor:pointer;" @click="openFileTab(option.history[0])">
                                        <u>{{ option.history[0].offer_filename }} </u>
                                      </a>
                                    </b-col>
                                    <!-- href="`${option.file_path}`" -->
                                    <!-- <img :src="option.file_path"> -->
                                    <!-- {{"../file_upload_dtc_backhaul/images/offer_file/"+option.history[0].offer_filename}} -->
                                    <!-- https://www.google.com-->
                                  </b-row>
                                  <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                    <b-col lg="12">
                                      หมายเหตุ: <span style="color: #0098D1">{{ option.history[0].other_service_display }}</span>
                                    </b-col>
                                  </b-row>
                                </div>

                                <div style="background: #FFF6E0 0% 0% no-repeat padding-box; margin-bottom:10px;">
                                  <b-row class="col-margin-bot-1" style="padding-left: 12px; padding-top: 12px; padding-bottom: 12px;">
                                    <!--  -->
                                    <b-col lg="12"> <b>การตอบกลับจากเจ้าของสินค้า</b> : {{ option.history[0].reject_comment }} </b-col>
                                    <!--  -->
                                  </b-row>
                                </div>
                              </div>

                              <div v-if="option.active_offer_all">
                                <!-- && option.active_status-->
                                <div v-for="(option2, index2) in option.history" :key="index2" class="service-offer-history-row-reject-body" :class="{ active: option.active_status }">
                                  <div style="background:#F7F7F7 0% 0% no-repeat padding-box; padding-top:5px; padding-bottom:5px; margin-bottom:10px;">
                                    <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                      <b-col lg="12">
                                        <span style="padding-left: 5px; color: #343C48; font-size:16px;"> <b>เสนอครั้งที่ {{ option.history.length - index2 }}</b> </span>
                                        ( {{ option2.offer_created | moment('dd DD/MM/YYYY HH:mm') }} น.)
                                      </b-col>
                                    </b-row>

                                    <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                      <b-col lg="6">
                                        <img v-if="option2.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" style="height: 30px;" />
                                        <img v-else-if="option2.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" style="height: 30px;" />
                                        <img v-else-if="option2.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" style="height: 30px;" />
                                        <img v-else-if="option2.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" style="height: 30px;" />
                                        <img v-else-if="option2.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" style="height: 30px;" />
                                        <img v-else-if="option2.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" style="height: 30px;" />
                                        <span style="padding-left: 5px; color: #343C48; font-size:16px;"> {{ option2.truck_type_name }} </span>
                                      </b-col>
                                      <b-col lg="6">
                                        เสนอราคา: <span style="padding-left: 5px; color: #343C48; font-size:16px;">{{ option2.price }} บาท </span>
                                        <!-- <label style="color:#33C38F"> ( {{option.history[0].discount}} บาท) </label> -->
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                      <b-col lg="6">
                                        จำนวนรถ: <span style="color: #0098D1">{{ option2.number_of_cars }} คัน </span>
                                      </b-col>
                                      <b-col lg="6">
                                        ใบเสนอราคา:
                                        <a target="_blank" style="color:black; cursor:pointer;" @click="openFileTab(option2)">
                                          <u>{{ option2.offer_filename }} </u>
                                        </a>
                                      </b-col>
                                      <!-- href="`${option.file_path}`" -->
                                      <!-- <img :src="option.file_path"> -->
                                      <!-- {{"../file_upload_dtc_backhaul/images/offer_file/"+option.history[0].offer_filename}} -->
                                      <!-- https://www.google.com-->
                                    </b-row>
                                    <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                                      <b-col lg="12">
                                        หมายเหตุ: <span style="color: #0098D1">{{ option2.other_service_display }}</span>
                                      </b-col>
                                    </b-row>
                                  </div>

                                  <div style="background: #FFF6E0 0% 0% no-repeat padding-box; margin-bottom:10px;">
                                    <b-row class="col-margin-bot-1" style="padding-left: 12px; padding-top: 12px; padding-bottom: 12px;">
                                      <!--  -->
                                      <b-col lg="12"> <b>การตอบกลับจากเจ้าของสินค้า</b> : {{ option2.reject_comment }} </b-col>
                                      <!--  -->
                                    </b-row>
                                  </div>
                                </div>
                              </div>

                              <div v-if="option.active_status && !option.active_offer_all" @click="display_all_reject(index)">
                                <b-row class="col-margin-bot-1">
                                  <b-col lg="12"> <u style="cursor: pointer;"> ข้อเสนอทั้งหมด </u></b-col>
                                </b-row>
                              </div>
                              <div v-if="option.active_status && option.active_offer_all" @click="display_all_reject(index)">
                                <b-row class="col-margin-bot-1">
                                  <b-col lg="12"> <u style="cursor: pointer;"> ลดการแสดงข้อเสนอ </u></b-col>
                                </b-row>
                              </div>

                              <div class="service-offer-history-button" :class="{ active: option.active_status }">
                                <b-row>
                                  <b-col lg="12">
                                    <button class="btn btn-success" style="width:100%;" :disabled="option.provider_status_code != 3" @click="open_modal_offer(option)">ยื่นข้อเสนอ</button>
                                  </b-col>
                                  <!-- <b-col lg="6">
                                    <button class="btn btn-outline-info" style="width:100%;" @click="alert_soon">soon</button>
                                  </b-col> -->
                                </b-row>
                              </div>
                            </b-card>
                          </div>
                        </simplebar>
                      </template>
                    </div>
                  </div>
                  <!-- END OFFER LIST-->
                </b-col>
              </b-row>
            </div>
          </div>
        </transition>

        <!-- MAIN OFFER PAGE-->
        <div style="padding: 2rem 2rem 0rem 2rem;">
          <b-row>
            <b-col lg="2" class="col-margin-bot-1">
              <select v-model="order_sel" class="form-control" @change="getPlansData()">
                <option value="0" selected hidden>เรียงตาม</option>
                <option value="1">วันที่สร้างแผนงาน</option>
                <option value="2">วันที่เริ่มแผนงาน</option>
              </select>
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <select v-model="sort_by_status" class="form-control" @change="getPlansData()">
                <option value="0" selected>สถานะทั้งหมด</option>
                <option value="1">รอเสนองาน</option>
                <option value="2">รอดำเนินการ</option>
                <option value="3">อยู่ระหว่างขนส่ง</option>
                <option value="4">ดำเนินการเสร็จแล้ว</option>
                <option value="5">ปิดงาน</option>
              </select>
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <date-picker v-model="search_date_start" input-class="form-control" format="DD-MM-YYYY" type="date" :placeholder="`${lang.placeholder.start_plan_date}`" />
            </b-col>
            <b-col lg="3" />
            <b-col lg="3" class="col-margin-bot-1">
              <div class="input-group prepend">
                <b-form-input id="search_plans_text" v-model="search_plans_text" type="text" class="form-control custom-input-prepend" :placeholder="lang.placeholder.search_plans_text" @keyup="timeout_search_plans()" />
                <div class="input-group-prepend custom-input-group-prepend" @click="search_plan('plan_code')">
                  <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" /></span>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="plans_data_status">
            <simplebar id="bankS" ref="bankscrol" class="card-body plans-main-display" style="padding: 0px">
              <transition-group name="bounce" class="custom-transition" enter-active-class="bounceInLeft" leave-active-class="bounceOutRight" tag="div">
                <b-col v-for="(option, index) in plans_row_data" :key="index+1" lg="12" class="plans-col" style="position: relative;">
                  <div class="plans-row-data" @click="activePlansDisplay(option)">
                    <b-row class="custom-margin">
                      <b-col>
                        <span>
                          <img v-if="option.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" height="25px" style="margin-right: 20px" />
                          <b style="font-size:18px;">{{ option.truck_type_name }}</b>
                        </span>
                      </b-col>
                      <b-col style="justify-items: baseline;">
                        <span class="plans-row-data-content"> {{ lang.code }} : </span>
                        <span class=" font-color-blue">
                          {{ option.rawData.id_carrier_tracking }}
                        </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content"> {{ lang.create_date }} : </span>
                        <span class=" font-color-blue">
                          {{ option.rawData.created | moment('dd DD/MM/YYYY HH:mm') }}
                        </span>
                      </b-col>
                      <b-col />
                    </b-row>
                    <b-row class="custom-margin">
                      <b-col>
                        <span class="plans-row-data-content"> {{ lang.number_of_car }} : </span>
                        <span class=" font-color-blue">
                          {{ option.rawData.number_of_cars }}
                        </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content"> {{ lang.payload }} : </span>
                        <span class=" font-color-blue"> {{ option.rawData.payload }} {{ lang.tons }} </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content"> {{ lang.status }} : </span>
                        <span class=" font-color-blue">
                          {{ option.status_name }}
                        </span>
                        <span v-if="option.rawData.carrier_status < 2" class=" font-color-red"> ( เสนอราคาถึงวันที่ {{ option.rawData.period | moment('dd DD/MM/YYYY') }} ) </span>
                      </b-col>
                      <b-col />
                    </b-row>
                    <b-row class="custom-margin">
                      <b-col>
                        <span class="plans-row-data-content">
                          {{ lang.pick_up_1 }}
                        </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content">
                          <i class="ri-arrow-right-fill" />
                        </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content">
                          {{ lang.drop_1 }}
                        </span>
                      </b-col>
                      <b-col />
                    </b-row>
                    <b-row class="custom-margin">
                      <b-col>
                        <span class=" font-color-blue">
                          {{ option.rawData.depart_address1 }}
                          <br />
                          {{ option.rawData.depart_date | moment('dd DD/MM/YYYY HH:mm') }}
                        </span>
                      </b-col>
                      <b-col />
                      <b-col>
                        <span class=" font-color-blue">
                          {{ option.rawData.arrive_address1 }}
                          <br />
                          {{ option.rawData.arrive_date | moment('dd DD/MM/YYYY HH:mm') }}
                        </span>
                      </b-col>
                      <b-col />
                    </b-row>
                  </div>
                  <div style="text-align:right;position: absolute;top:0px;right:20px;z-index:9999;">
                    <i :class="option.rawData.bookmark_flg == 1 ? 'ri-bookmark-fill': 'ri-bookmark-line'" :style="option.rawData.bookmark_flg == 1 ? 'color:#FAAB00;cursor:pointer;font-size:30px;' : 'cursor:pointer;font-size:30px;'" @click="update_favorite(option, index)" />
                  </div>
                </b-col>
              </transition-group>
              <button v-show="activeScroll" style="display:scroll;position:fixed;bottom:10px;right:35px;z-index:999999999999;border:0;background:#ffffff00;" @click="toTop"><img style="width:50px" src="@/assets/images/imgbank/bankscroll.png" /></button>
            </simplebar>
          </b-row>
          <b-row v-else>
            <b-col>
              <br />
              <br />
              <br />
              <br />
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <img src="@/assets/images/search.png" height="188px" />
              </b-col>
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <span style="font-size: 24px; font-weight: 600;">
                  ไม่พบ "แผนงานรถว่าง" ของคุณ
                </span>
              </b-col>
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <button type="button" class="btn btn-primary" @click="routersClick2('/carrier/create')">
                  <!-- <img src="@/assets/images/right_bar_menu/plus (1).svg" height="18px" width="18px" class="icon-side-nav-menu" /> -->
                  <i class="fa fa-plus" aria-hidden="true" />
                  ลงเวลารถว่าง
                </button>
              </b-col>
            </b-col>
          </b-row>
        </div>
        <!-- END MAIN OFFER PAGE -->
      </b-col>
    </b-row>

    <b-modal id="modal-xl" size="xl" title="ยื่นข้อเสนอ" title-class="font-18" hide-footer>
      <!-- <form class="form-horizontal" role="form"> -->
      <b-form-group id="" label-cols-sm="1" label-cols-lg="1" label="ประเภทรถ:" label-for="text">
        <multiselect v-model="offer_truck_type" :options="option_truck_type" label="name" track-by="id_model" :reduce="option => option.id" placeholder="เลือกประเภทรถ" select-label="" selected-label="เลือกแล้ว" deselect-label="เลือกอีกครั้งเพื่อนำออก">
          <template slot="singleLabel" slot-scope="value">
            <span class="multiselect__single">
              <img v-if="value.option.id_group == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width:70px; height: 34px;" />
              <img v-else-if="value.option.id_group == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width:70px; height: 34px;" />
              <img v-else-if="value.option.id_group == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width:70px; height: 34px;" />
              <img v-else-if="value.option.id_group == '4'" src="@/assets/images/truck_img/trailer2.png" style="width:70px; height: 34px;" />
              <img v-else-if="value.option.id_group == '5'" src="@/assets/images/truck_img/trailer.png" style="width:70px; height: 34px;" />
              <img v-else-if="value.option.id_group == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width:70px; height: 34px;" />
              {{ value.option.name }}
              <!-- {{value.option.id_group}} {{value.option}} -->
            </span>
          </template>

          <template slot="option" slot-scope="curr_option">
            <span v-if="curr_option.option.display_img == false" />
            <span v-else>
              <img v-if="curr_option.option.id_group == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width:70px; height: 34px;" />
              <img v-else-if="curr_option.option.id_group == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width:70px; height: 34px;" />
              <img v-else-if="curr_option.option.id_group == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width:70px; height: 34px;" />
              <img v-else-if="curr_option.option.id_group == '4'" src="@/assets/images/truck_img/trailer2.png" style="width:70px; height: 34px;" />
              <img v-else-if="curr_option.option.id_group == '5'" src="@/assets/images/truck_img/trailer.png" style="width:70px; height: 34px;" />
              <img v-else-if="curr_option.option.id_group == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width:70px; height: 34px;" />
            </span>
            {{ curr_option.option.name }}
          </template>
        </multiselect>
        <!-- {{offer_truck_type}} -->
      </b-form-group>

      <b-form-group id="" label-cols-sm="1" label-cols-lg="1" label="จำนวนรถ:" label-for="text">
        <b-input-group append="คัน">
          <b-form-input v-model="modal_offer.truck_quantity" for="text" value="" placeholder="ระบุจำนวนรถ" />
        </b-input-group>
      </b-form-group>

      <b-form-group id="" label-cols-sm="1" label-cols-lg="1" label="เสนอราคา:" label-for="text">
        <b-input-group append="บาท">
          <!-- prepend="$"-->
          <b-form-input id="" v-model="modal_offer.cost" for="text" value="" placeholder="ระบุราคา" />
        </b-input-group>
      </b-form-group>

      <b-form-group id="" label-cols-sm="1" label-cols-lg="1" label="ใบเสนอราคา:" label-for="text">
        <!-- <b-form-input for="text" value="" placeholder="เลือกไฟล์"/> -->
        <div class="custom-file">
          <!-- v-model="offer_upload_file"-->
          <input id="offerfile" ref="offerfile" type="file" class="custom-file-input " :class="{ 'is-invalid': !offer_correct_file }" @input="handleFileUpload()" />
          <label v-if="offer_upload_display !== null" class="custom-file-label" for="customFile"> {{ offer_upload_display }} </label>
          <!--  -->
          <label v-else class="custom-file-label" for="customFile"> เลือกไฟล์</label>
        </div>
        <span style="color:red; font-size:10px;">(รองรับไฟล์ .pdf, .jpg, .png)</span>
      </b-form-group>

      <b-form-group id="" label-cols-sm="1" label-cols-lg="1" label="หมายเหตุ:" label-for="text">
        <textarea id="billing-address" v-model="modal_offer.note" class="form-control" rows="3" placeholder="ระบุหมายเหตุ" />
        <!-- <b-form-input for="textarea" rows="3" value="" placeholder="ระบุหมายเหตุ" /> -->
      </b-form-group>
      <hr />
      <b-row>
        <b-col lg="12" class="text-center">
          <button class="btn btn-success" style="" @click="confirm_offer()">ยืนยัน</button> &nbsp;
          <button class="btn btn-outline-dark" style="" block @click="close_modal_offer()">ยกเลิก</button>
        </b-col>
      </b-row>
      <!-- </form> -->
    </b-modal>

    <!-- MODAL CANCEL OFFER -->
    <!-- size="xl" title="ยื่นข้อเสนอ"-->
    <b-modal id="modal-cancel" title-class="font-18" hide-footer hide-header>
      <b-row class="col-margin-bot-1">
        <b-col lg="12">
          <center>
            <img src="@/assets/images/plans_display/warning.png" width="120px" height="120px" />
          </center>
        </b-col>
      </b-row>

      <b-row class="col-margin-bot-1">
        <b-col lg="12">
          <center>
            <span style="font-size:24px; font-weight:500;">ยกเลิกแผนขนส่ง "{{ id_cancel_display }}" </span><br />
            โปรดระบุสาเหตุที่ยกเลิก "แผนงานขนส่ง" นี้
          </center>
        </b-col>
      </b-row>
      <b-row class="col-margin-bot-1">
        <b-col lg="12">
          <multiselect v-model="id_cancel_sel" :options="option_cancel" label="name" track-by="option_id" placeholder="เลือกสาเหตุ" select-label="" selected-label="เลือกแล้ว" deselect-label="เลือกอีกครั้งเพื่อนำออก">
            <template slot="singleLabel" slot-scope="value">
              <span class="multiselect__single">
                {{ value.option.name }}
              </span>
            </template>
          </multiselect>
        </b-col>
      </b-row>
      <b-row class="col-margin-bot-1">
        <b-col lg="12" class="text-center">
          <button class="btn btn-success" style="" @click="confirm_cancel_plan()">ยืนยัน</button> &nbsp;
          <!--@click="" -->
          <button class="btn btn-outline-dark" style="" @click="close_cancel_plan_modal()">ยกเลิก</button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- END MODAL CANCEL OFFER -->

    <b-modal id="modal-truck" size="xl" title="ระบุข้อมูลรถ" title-class="font-18" hide-footer>
      <!-- <form class="form-horizontal" role="form"> -->
      <b-row>
        <b-col lg="6" style="display:flex;">
          <div style="width:100px;">ประเภทรถ : </div>
          <multiselect v-model="offer_truck_type" :options="option_truck_type" label="name" track-by="id_model" :reduce="option => option.id" placeholder="เลือกประเภทรถ" select-label="" selected-label="เลือกแล้ว" deselect-label="เลือกอีกครั้งเพื่อนำออก">
            <template slot="singleLabel" slot-scope="value">
              <span class="multiselect__single">
                <img v-if="value.option.id_group == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width:30px; " />
                <img v-else-if="value.option.id_group == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width:30px; " />
                <img v-else-if="value.option.id_group == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width:30px; " />
                <img v-else-if="value.option.id_group == '4'" src="@/assets/images/truck_img/trailer2.png" style="width:30px; " />
                <img v-else-if="value.option.id_group == '5'" src="@/assets/images/truck_img/trailer.png" style="width:30px; " />
                <img v-else-if="value.option.id_group == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width:30px; " />
                {{ value.option.name }}
              <!-- {{value.option.id_group}} {{value.option}} -->
              </span>
            </template>
            <template slot="option" slot-scope="curr_option">
              <span v-if="curr_option.option.display_img == false" />
              <span v-else>
                <img v-if="curr_option.option.id_group == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width:60px;" />
                <img v-else-if="curr_option.option.id_group == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width:60px;" />
                <img v-else-if="curr_option.option.id_group == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width:60px;" />
                <img v-else-if="curr_option.option.id_group == '4'" src="@/assets/images/truck_img/trailer2.png" style="width:60px;" />
                <img v-else-if="curr_option.option.id_group == '5'" src="@/assets/images/truck_img/trailer.png" style="width:60px;" />
                <img v-else-if="curr_option.option.id_group == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width:60px;" />
              </span>
              {{ curr_option.option.name }}
            </template>
          </multiselect>
        <!-- {{offer_truck_type}} -->
        </b-col>
      </b-row>
      <div v-if="data_truck_bank.length > 0">
        <hr />
        <b-form-input v-model="filter" placeholder="ค้นหาทะเบียนรถ" />
      </div>
      <div v-for="(data,ix) in filters" :key="ix">
        <!-- v-for="(data,ix) in filters" :key="ix" -->
        <hr />
        <div class="d-flex align-items-center">
          <!-- <b-form-checkbox-group  v-model="selected" :options="filters" > -->
          <input v-model="selected" type="checkbox" :value="data.id_member_vehicle" style="margin:0 10px 0 0;" />
          <div style="display:grid;grid-template-columns: 1fr 1fr;width:100%">
            <div>
              <span>
                ทะเบียนรถ : {{ data.truck_license }}
              </span>
            </div>
            <div>
              <img v-if="data.id_group_vehicle == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width:60px;" />
              <img v-else-if="data.id_group_vehicle == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width:60px;" />
              <img v-else-if="data.id_group_vehicle == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width:60px;" />
              <img v-else-if="data.id_group_vehicle == '4'" src="@/assets/images/truck_img/trailer2.png" style="width:60px;" />
              <img v-else-if="data.id_group_vehicle == '5'" src="@/assets/images/truck_img/trailer.png" style="width:60px;" />
              <img v-else-if="data.id_group_vehicle == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width:60px;" />
              <span class="font-color-blue" style="padding-left:10px;">{{ data.name_model }}</span>
            </div>
          </div>

          <!-- </b-form-checkbox-group> -->
          <!-- <div style="display:grid;grid-template-columns: 1fr 1fr;width:100%">
                    <div >
                      blackbox_id : {{data.blackbox_id}}
                    </div>
                    <div>
                      <img v-if="data.id_group_vehicle == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width:60px;" />
                      <img v-else-if="data.id_group_vehicle == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width:60px;" />
                      <img v-else-if="data.id_group_vehicle == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width:60px;" />
                      <img v-else-if="data.id_group_vehicle == '4'" src="@/assets/images/truck_img/trailer2.png" style="width:60px;" />
                      <img v-else-if="data.id_group_vehicle == '5'" src="@/assets/images/truck_img/trailer.png" style="width:60px;" />
                      <img v-else-if="data.id_group_vehicle == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width:60px;" />
                      <span class="font-color-blue" style="padding-left:10px;">{{data.name_model}}</span>
                    </div>
              </div> -->
        </div>
      </div>
      <hr />
      <b-row>
        <b-col lg="12" class="text-center">
          <button class="btn btn-success" style="" @click="create_truck()">ยืนยัน</button> &nbsp;
          <!-- <button :disabled="selected.length > 0 ? false : true" @click="create_truck()" class="btn btn-success" style="" >ยืนยัน</button> &nbsp; -->
          <button class="btn btn-outline-dark" style="" block @click="$bvModal.hide('modal-truck')">ยกเลิก</button>
        </b-col>
      </b-row>
      <!-- </form> -->
    </b-modal>

    <PlanDetail ref="plandetail" :obj="{}" />
    <!-- offer_detail_data -->

    <!-- company detail -->
    <ModalCompany ref="modalCompany" :obj="{}" />

    <topbar ref="topbar" :obj="{}" @getPlansData="getPlansData" />
    <ModalProof ref="modalProof" :obj="{}" @fin-plan="sendFinPlan" />
  </Layout>
</template>
<style>
body {
  overflow: hidden;
}
hr {
  border: 1px solid #e4e4e4;
}
.col-main-menu-tran {
  padding: 0px;
}
.h-100vh {
  height: 100% !important;
}
.plans-row-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
  max-height: 230px;
  min-height: 230px;
  padding: 1rem;
}
.plans-row-data:hover {
  transition: 0.3s;
  border: 1px solid #03aeef;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.plans-col {
  /* transition: all 1s; */
  display: inline-block;
  margin: 0 0 0.5rem 0;
}
.plans-main-display {
  max-height: 77.5vh;
  min-height: 77.5vh;
}
.plans-row-data-title {
  font-size: 24px;
  font-weight: 500;
}
.plans-row-data-content {
  font-size: 16px;
  font-weight: 500;
}
.custom-margin {
  margin-bottom: 0.5rem;
}
.summary-of-plan.active {
  position: absolute;
  width: 100%;
  z-index: 13;
}
.plans_detail_title {
  font-size: 32px;
  font-weight: 500;
}
.avatar-title {
  /* background-color: #fff !important; */
  background-color: hsla(197, 100%, 89%, 0) !important;
}
.col-margin-bot-1 {
  margin-bottom: 0.6rem;
}
.service-provider-display {
  padding: 20px;
}
</style>
